import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetDataUser } from "../../axios/auth";
import { setDataUser, logoutSuccess } from "../../redux/userSlice";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const user = useSelector((state) => state.user.user.dataBasic);
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDataInitial();
  }, []);

  const getDataInitial = async () => {
    const dataInLocalStorage = localStorage.getItem("access_token");

    if (dataInLocalStorage) {
      if (user === null) {
        let response = await GetDataUser(user_id);
        if (response.status === 200) {
          dispatch(setDataUser(response.data));
        } else {
          dispatch(logoutSuccess());
          navigate("/security/login", { replace: true });
        }
      }
      setLoading(false);
    } else {
      navigate("/security/login", { replace: true });
    }
  };

  return (
    <>
      {!loading && (
        <StyledRoot>
          <Header onOpenNav={() => setOpen(true)} />

          <Nav openNav={open} onCloseNav={() => setOpen(false)} />

          <Main>
            <Outlet />
          </Main>
        </StyledRoot>
      )}
    </>
  );
}
