import axiosApi from "./axiosApi";

export const ServiceLogin = async (USER) => {
  try {
    const result = await axiosApi().post("/auth/login/", USER);
    return { status: 200, data: result.data, msg: "Logueado correctamente" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const RequestChangePassword = async (data) => {
  try {
    const result = await axiosApi().post(
      "/auth/request-change-password/",
      data,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Se envió un correo con instrucciones, síguelas",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const VerifyTokenRequestChangePassword = async (data) => {
  try {
    const result = await axiosApi().post(
      "/auth/verify-token-by-request-password/",
      data,
    );
    return { status: 200, data: result.data, msg: "Se valida el token" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const ChangePassword = async (data) => {
  try {
    const result = await axiosApi().post("/auth/change-password/", data);
    return {
      status: 200,
      data: result.data,
      msg: "Se cambio el password exitosamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataUser = async (id) => {
  try {
    const result = await axiosApi().get(`/auth/user/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Usuario obtenido correctamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteAccount = async (data) => {
  try {
    const result = await axiosApi().post("/auth/delete-account/", data);
    return {
      status: 200,
      data: result.data,
      msg: "Se eliminó la cuenta correctamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};