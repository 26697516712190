import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import RecoverForm from "../../components/RecoverForm";

function RequestChangePassword() {
  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 5),
  }));

  return (
    <StyledContent>
      <img
        style={{ alignSelf: "center", height: "100px" }}
        alt={""}
        src="/assets/logo.png"
      />

      <Typography style={{ alignSelf: "center" }} variant="h4" sx={{ mb: 5 }}>
        ¡Hola, bienvenido!
      </Typography>

      <RecoverForm />
    </StyledContent>
  );
}

export default RequestChangePassword;
