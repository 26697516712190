import React from "react";
import 'simplebar-react/dist/simplebar.min.css';
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./theme";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import Router from "./routes";
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
