import { useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// components
import Iconify from "../../utils/iconify";
import Scrollbar from "../scrollbar";
// sections
import ListHead from "./ListHead";
import ListToolbar from "./ListToolBar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function TableCustom({
  dataList = [],
  dataHeader = [],
  titleCustom = "",
  titleCustomPlural = "",
  order_by_default = "order",
  type_order_by_default = "asc",
  includeCreate = false,
  includeCheck = false,
  includeActions = false,
  includePhoto = false,
  totalData = 0,
  search,
  setSearch,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  deleteItem = () => console.log("eliminación default informativo"),
  setOpenForm = (value) => console.log("No se pasó el parámetro openform"),
  editItem = (value) => console.log("No se agregó editItem a los parámetros"),
  handleFind = (value) => console.log("No se agregó manejador de búsqueda"),
}) {
  const [open, setOpen] = useState(null);

  const [order, setOrder] = useState(type_order_by_default);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState(order_by_default);

  const [filterName, setFilterName] = useState("");

  const [currentIdAction, setCurrentIdAction] = useState(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleOpenMenu = (event, id) => {
    setCurrentIdAction(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setCurrentIdAction(null);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    // Función para el cambio de página
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    // Función para cambiar las filas por página, debe
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    // Función que se usa para el buscador de nombres
    setFilterName(event.target.value);
  };

  const filteredData = applySortFilter(dataList, getComparator(order, orderBy));

  const isNotFound = !filteredData.length && !!filterName;

  const resultTableItem = (row, id) => {
    const elements = Object.keys(row);
    let initElement = true;
    return elements.map((element) => {
      if (element !== "id") {
        if (element !== "photo_url") {
          if (initElement) {
            initElement = false;
            return (
              <TableCell
                component="th"
                scope="row"
                padding={includeCheck ? "none" : "10px"}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  {includePhoto && (
                    <Avatar
                      alt={id}
                      src={
                        row.photo_url
                          ? row.photo_url
                          : "/assets/Default_person.png"
                      }
                    />
                  )}
                  <Typography variant="subtitle2" noWrap>
                    {row[element]}
                  </Typography>
                </Stack>
              </TableCell>
            );
          } else {
            return <TableCell align="left">{row[element]}</TableCell>;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  };

  const handleDeleteClick = () => {
    setOpen(null);
    setOpenConfirmationDialog(true);
  };

  const handleClic = (value) => {
    if (value === "") {
      setFilterName("");
      handleFind("");
    } else {
      handleFind(filterName);
    }
  };

  const handlerDeleted = async () => {
    await deleteItem(selected.length > 0 ? selected : currentIdAction);
    setSelected([]);
  };

  return (
    <>
      {/* <Helmet>
        <title> User | Minimal UI </title>
      </Helmet> */}

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {titleCustomPlural}
          </Typography>
          {includeCreate && (
            <Button
              onClick={() => setOpenForm(true)}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Crear {titleCustom}
            </Button>
          )}
        </Stack>

        <Card>
          <ListToolbar
            setOpenConfirmationDialog={setOpenConfirmationDialog}
            search={search}
            setSearch={setSearch}
            handleClic={handleClic}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            titleCustom={titleCustom?.toLowerCase()}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={dataHeader}
                  rowCount={dataList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  includeCheck={includeCheck}
                  includeActions={includeActions}
                />
                <TableBody>
                  {filteredData.map((row) => {
                    const { id } = row;
                    const selectedData = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedData}
                      >
                        {includeCheck && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedData}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                        )}

                        {resultTableItem(row, id)}

                        {includeActions && (
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, id)}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Sin resultados
                          </Typography>

                          <Typography variant="body2">
                            No se encontraron resultados para &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Intente comprobar si hay errores tipográficos
                            o utilizar palabras completas.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            editItem(currentIdAction);
            handleCloseMenu();
          }}
        >
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Editar
        </MenuItem>

        <MenuItem onClick={handleDeleteClick} sx={{ color: "error.main" }}>
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Eliminar
        </MenuItem>
      </Popover>
      {openConfirmationDialog && (
        <Dialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
        >
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {selected.length > 0
                ? `¿Está seguro de eliminar ${selected.length} elementos?`
                : "¿Está seguro de que desea eliminar este elemento?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenConfirmationDialog(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setOpenConfirmationDialog(false);
                handlerDeleted();
              }}
              color="error"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
