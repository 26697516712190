import { useState, useEffect } from "react";
import TableCustom from "../../components/table-custom";
import ModalAddEdit from "../../components/forms/catalog/QualificationCatalogForm";
import {
  GetData,
  DeleteData,
  CreateData,
  GetDataItem,
  EditData,
  DeleteDataMassive,
} from "../../axios/catalog/qualification";
import { successToast, errorToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";

export default function QualificationCatalogPage() {
  //////////////////////////// Datos estáticos ////////////////////////////
  const TABLE_HEAD = [{ id: "name", label: "Nombre", alignRight: false }];

  //////////////////////////// Definición de estados ////////////////////////////
  const [openForm, setOpenForm] = useState(false);
  const [openFormEdit, setOpenFormEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  //////////////////////////// Manejo de estados ////////////////////////////
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  }, [rowsPerPage]);

  const handleFind = (value) => {
    setSearch(value);
    if (page === 1) {
      getInfo(value);
    } else {
      setPage(1);
    }
  };

  const editItem = async (id) => {
    setOpenFormEdit(true);
    let dataItem = await GetDataItem(id);
    if (dataItem.status === 200) {
      setDataEdit(dataItem.data);
    }
    setOpenForm(true);
  };

  //////////////////////////// Consumo de backend ////////////////////////////
  const getInfo = async (search_force) => {
    let info;
    if (search_force) {
      info = await GetData(page, rowsPerPage, search_force);
    } else {
      if (search_force === "") {
        info = await GetData(page, rowsPerPage);
      } else {
        info = await GetData(page, rowsPerPage, search);
      }
    }
    if (info.status === 200) {
      let newInfo = info.data.data.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setDataList(newInfo);
      setTotalData(info.data.total_records);
    }
  };

  const createItem = async (data) => {
    let createData = await CreateData(data);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const editItemFunction = async (data, id) => {
    let createData = await EditData(data, id);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const deleteItem = async (element) => {
    let deletedData;
    if (Array.isArray(element)) {
      deletedData = await DeleteDataMassive(element);
    } else {
      deletedData = await DeleteData(element);
    }
    if (deletedData.status === 200) {
      successToast(deletedData.msg);
    } else {
      errorToast(deletedData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
    return;
  };

  //////////////////////////// Render ////////////////////////////
  return (
    <>
      {/* <Helmet>
        <title> User | Minimal UI </title>
      </Helmet> */}

      <TableCustom
        dataList={dataList}
        dataHeader={TABLE_HEAD}
        titleCustom="Calificación"
        titleCustomPlural={
          search !== ""
            ? `Calificaciones (filtradas por '${search}')`
            : "Calificaciones"
        }
        includeCreate={true}
        includeCheck={true}
        includeActions={true}
        includePhoto={false}
        page={page}
        totalData={totalData}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        deleteItem={deleteItem}
        setOpenForm={setOpenForm}
        editItem={editItem}
        handleFind={handleFind}
        order_by_default="name"
        type_order_by_default="asc"
      />
      {openForm && (
        <ModalAddEdit
          dataEdit={dataEdit}
          editItemFunction={editItemFunction}
          createItem={createItem}
          open={openForm}
          openEdit={openFormEdit}
          onClose={() => {
            setOpenForm(false);
            setOpenFormEdit(false);
          }}
          titleCustom={"Calificación"}
        />
      )}
      <ToastContainer />
    </>
  );
}
