// TableHeader.js
import React from 'react';
import { TableRow, TableCell } from '@mui/material';

const TableHeader = ({ headers }) => {
  return (
    <TableRow>
      <TableCell>Headers</TableCell>
      {headers.map((header) => (
        <TableCell key={header.id}>{header.name}</TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeader;
