export const formatPhoneNumber = (value) => {
    // // Eliminar cualquier caracter que no sea un número
    // const phoneNumber = value.replace(/[^\d]/g, '');

    // // Aplicar el formato: agregar un guión después de cada par de dígitos
    // const formattedPhoneNumber = phoneNumber.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');

    const inputValue = value.replace(/[^\d]/g, ''); // Elimina caracteres que no sean dígitos
    const formattedValue = inputValue
      .slice(0, 10) // Limita la longitud a 10 dígitos
      .replace(/(\d{3})(\d{1,3})?(\d{0,3})?/, (_, p1, p2, p3) => {
        // Agrega guiones después de cada par de dígitos
        return p1 + (p2 ? '-' + p2 : '') + (p3 ? '-' + p3 : '') ;
      });
    return formattedValue;
  };