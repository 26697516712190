import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import {
  GetDataForm as GetStatesApi
} from "../../axios/catalog/state";
import {
  GetDataForm as GetCitiesApi
} from "../../axios/catalog/city";
import { formatPhoneNumber } from "../../utils/phoneNumber";

const InstallerModalForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  dataLevel = [],
  dataOffice = []
}) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    birthday: "",
    address_1: "",
    address_2: "",
    city_id: "",
    state_id: "",
    zip_code: "",
    level_id: "",
    office_id: "",
  });
  const [dataStates, setDataStates] = useState([])
  const [dataCities, setDataCities] = useState([])

  const user = useSelector((state) => state.user.user.dataBasic);

  useEffect(() => {
    if (openEdit) {
      dataFillEdit();
    }
  }, [openEdit]);

  useEffect(() => {
    if (open) {
      getStates()
    }
  }, [open]);

  const getStates = async () =>{
    let states_list = await GetStatesApi()
    if (states_list.status == 200){
      setDataStates(states_list.data.data)
    }
  }

  const getCities = async (id) =>{
    let cities_list = await GetCitiesApi(id)
    if (cities_list.status == 200){
      setDataCities(cities_list.data.data)
    }
  }

  const dataFillEdit = async() => {
    let dateObject = null;
    if (dataEdit.birthday) {
      const [year, month, day] = dataEdit.birthday.split("-");
      dateObject = new Date(year, month - 1, day);
    }
    await getCities(dataEdit.state_id)
    setFormData({
      first_name: dataEdit.first_name,
      last_name: dataEdit.last_name,
      email: dataEdit.email,
      phone: dataEdit.phone,
      birthday: dateObject,
      address_1: dataEdit.address_1,
      address_2: dataEdit.address_2,
      city_id: dataEdit.city_id,
      state_id: dataEdit.state_id,
      zip_code: dataEdit.zip_code,
      level_id: dataEdit.level.id,
      office_id: dataEdit.office?.id
    });
  };

  const handleChange = async(event) => {
    if (event.target.name == 'state_id'){
      setFormData({
        ...formData,
        city_id : "",
        [event.target.name]: event.target.value
      })
      await getCities(event.target.value)
    }else if(event.target.name == 'phone'){
      const formattedValue = formatPhoneNumber(event.target.value);
      setFormData({ ...formData, [event.target.name]: formattedValue });
    }else{
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleDateChange = (field, newDate) => {
    setFormData({
      ...formData,
      [field]: newDate,
    });
  };

  const handleSubmit = async () => {
    if (openEdit) {
      await editItemFunction(formData, dataEdit.id);
    } else {
      await createItem(formData);
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          maxHeight: "80vh",
          position: "absolute",
          overflowY: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          "& > *": {
            // marginBottom: '16px', // Separación vertical uniforme entre elementos
            width: "100%", // Hacer que los elementos ocupen todo el ancho del modal
          },
        }}
      >
        <h2>
          {openEdit ? "Editar" : "Crear"} {titleCustom?.toLowerCase()}
        </h2>
        <TextField
          name="first_name"
          label="Nombre"
          value={formData.first_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="last_name"
          label="Apellidos"
          value={formData.last_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="email"
          label="Correo electrónico"
          value={formData.email}
          onChange={handleChange}
          disabled={openEdit}
          fullWidth
          margin="normal"
        />
        <TextField
          name="phone"
          label="Teléfono"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
          sx={{ marginBottom: "25px" }}
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            fullWidth
            label="Cumpleaños"
            name="birthday"
            value={formData.birthday}
            onChange={(newDate) => handleDateChange("birthday", newDate)}
            TextField={(params) => (
              <TextField {...params} variant="outlined" fullWidth />
            )}
          />
        </LocalizationProvider>

        <TextField
          name="address_1"
          label="Dirección 1"
          value={formData.address_1}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="address_2"
          label="Dirección 2"
          value={formData.address_2}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="state_id"
          label="Estado"
          value={formData.state_id}
          select
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {dataStates.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        <TextField
          name="city_id"
          label="Ciudad"
          value={formData.city_id}
          onChange={handleChange}
          select
          fullWidth
          margin="normal"
        >
          {dataCities.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        <TextField
          name="zip_code"
          label="Código postal"
          value={formData.zip_code}
          onChange={handleChange}
          fullWidth
          margin="normal"
          sx={{ marginBottom: "25px" }}
        />
       {dataLevel.length > 0 && <TextField
          fullWidth
          label="Nivel"
          name="level_id"
          select
          value={formData.level_id}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          {dataLevel.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>}
        {
          dataOffice.length > 0 &&
          <TextField
            fullWidth
            label="Oficina"
            name="office_id"
            select
            value={formData.office_id}
            onChange={handleChange}
            sx={{ mb: 2 }}
          >
            {dataOffice.map((item) => {
              return <MenuItem value={item.id}>{item.name}, {item.city.name}, {item.state.name}</MenuItem>;
            })}
          </TextField>
        }

        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {openEdit ? "Editar" : "Crear"}
        </Button>
      </Box>
    </Modal>
  );
};

export default InstallerModalForm;
