import { createSlice } from "@reduxjs/toolkit";

let dataUser = null;
let accessToken = null;
let user_id = null;

if (typeof window !== "undefined") {
  accessToken = localStorage.getItem("access_token")
    ? JSON.parse(localStorage.getItem("access_token"))
    : null;
  user_id = localStorage.getItem("data")
    ? JSON.parse(localStorage.getItem("data"))
    : null;
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    accessToken: accessToken,
    userId: user_id,
    user: {
      dataBasic: dataUser,
    },
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.accessToken = action.payload.access_token;
      state.userId = action.payload.user_id;
      localStorage.setItem(
        "access_token",
        JSON.stringify(action.payload.access_token),
      );
      localStorage.setItem("data", JSON.stringify(action.payload.user_id));
    },
    setDataUser: (state, action) => {
      state.user = {
        dataBasic: {
          // ...action.payload.user,
          photo_url: action.payload.profile.photo_url,
          parent_user_id: action.payload.profile.parent_user? action.payload.profile.parent_user.id:undefined,
          name:
            action.payload.profile.first_name +
            " " +
            action.payload.profile.last_name,
          level: action.payload.profile.level.name,
          level_id: action.payload.profile.level.id,
          level_order: action.payload.profile.level.order,
          profile_id: action.payload.profile.id,
          office_id: action.payload.office_id,
          office: action.payload.office,
          director_id: action.payload.office ? action.payload.office.owner_id : undefined,
          user_id: action.payload.profile.user.id
        },
      };
    },
    setUrlPhoto: (state, action) => {
      state.user = {
        dataBasic: {
          ...state.user.dataBasic,
          photo_url: action.payload,
        },
      };
    },
    updateDataUser: (state, action) => {
      state.user = {
        dataBasic: {
          ...state.user.dataBasic,
          photo_url: action.payload.photo_url,
          name: action.payload.first_name + " " + action.payload.last_name,
        },
      };
    },
    logoutSuccess: (state, action) => {
      state.user = { dataBasic: null };
      state.accessToken = null;
      state.userId = null;
      localStorage.removeItem("access_token");
      localStorage.removeItem("data");
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  setDataUser,
  setUrlPhoto,
  updateDataUser,
} = userSlice.actions;

export default userSlice.reducer;
