import { useState, useEffect } from "react";
import TableCustom from "../../components/table-custom";
import ModalAddEdit from "../../components/forms/WorkcenterForm";
import {
  GetData,
  DeleteData,
  CreateData,
  GetDataItem,
  EditData,
  DeleteDataMassive,
} from "../../axios/config/workcenter";
import { successToast, errorToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

export default function RecommendedPage() {
  //////////////////////////// Datos estáticos ////////////////////////////
  const TABLE_HEAD = [
    { id: "name", label: "Nombre", alignRight: false },
    { id: "city", label: "Ciudad", alignRight: false },
    { id: "state", label: "Estado", alignRight: false },
    { id: "owner", label: "Director", alignRight: false },
  ];

  const user = useSelector((state) => state.user.user.dataBasic);

  //////////////////////////// Definición de estados ////////////////////////////
  const [openForm, setOpenForm] = useState(false);
  const [openFormEdit, setOpenFormEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  //////////////////////////// Manejo de estados ////////////////////////////
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  }, [rowsPerPage]);

  const handleFind = (value) => {
    setSearch(value);
    if (page === 1) {
      getInfo(value);
    } else {
      setPage(1);
    }
  };

  const editItem = async (id) => {
    setOpenFormEdit(true);
    let dataItem = await GetDataItem(id);
    if (dataItem.status === 200) {
      setDataEdit(dataItem.data);
    }
    setOpenForm(true);
  };

  //////////////////////////// Consumo de backend ////////////////////////////
  const getInfo = async (search_force) => {
    let info;
    if (search_force) {
      info = await GetData(page, rowsPerPage, search_force, false, true);
    } else {
      if (search_force === "") {
        info = await GetData(page, rowsPerPage, undefined, false, true);
      } else {
        info = await GetData(page, rowsPerPage, search, false, true);
      }
    }
    if (info.status === 200) {
      let newInfo = info.data.data.map((item) => ({
        id: item.id,
        name: item.name,
        city: item.city?.name ? item.city.name: "",
        state: item.state?.name ? item.state.name : "",
        owner: item?.owner?.first_name
          ? item.owner.first_name + " " + item.owner.last_name
          : "",
      }));
      setDataList(newInfo);
      setTotalData(info.data.total_records);
    }
  };

  const createItem = async (data) => {
    let data2 = {}
    if (user.level_order == 0){
      data2 = data;
    }else{
      data2 = { ...data, owner_id: user.profile_id };
    }
    let createData = await CreateData(data2);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const editItemFunction = async (data, id) => {
    let data2 = {}
    if (user.level_order == 0){
      data2 = data;
    }else{
      data2 = { ...data, owner_id: user.profile_id };
    }
    let createData = await EditData(data2, id);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const deleteItem = async (element) => {
    let deletedData;
    if (Array.isArray(element)) {
      deletedData = await DeleteDataMassive(element);
    } else {
      deletedData = await DeleteData(element);
    }
    if (deletedData.status === 200) {
      successToast(deletedData.msg);
    } else {
      errorToast(deletedData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
    return;
  };

  //////////////////////////// Render ////////////////////////////
  return (
    <>
      {/* <Helmet>
        <title> User | Minimal UI </title>
      </Helmet> */}

      <TableCustom
        dataList={dataList}
        dataHeader={TABLE_HEAD}
        titleCustom="Oficina"
        titleCustomPlural={
          search !== "" ? `Oficinas (filtrados por '${search}')` : "Oficinas"
        }
        includeCreate={true}
        includeCheck={true}
        includeActions={true}
        includePhoto={false}
        page={page}
        totalData={totalData}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        deleteItem={deleteItem}
        setOpenForm={setOpenForm}
        editItem={editItem}
        handleFind={handleFind}
        order_by_default="name"
        type_order_by_default="asc"
      />
      {openForm && (
        <ModalAddEdit
          dataEdit={dataEdit}
          editItemFunction={editItemFunction}
          createItem={createItem}
          open={openForm}
          openEdit={openFormEdit}
          onClose={() => {
            setOpenForm(false);
            setOpenFormEdit(false);
          }}
          titleCustom={"Oficina"}
          level_order={user.level_order}
        />
      )}
      <ToastContainer />
    </>
  );
}
