import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    CircularProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import SimpleBar from 'simplebar-react';
import CustomLoading from './CustomLoading';
import { styled } from '@mui/material/styles';
import CustomSearch from './CustomSearch';

export const Scrollbar = styled(SimpleBar)``;

const CustomTable = (props) => {
    const {
        count = 0,
        items = [],
        columns = [],
        rowKey = '',
        loading = false,
        onDeselectAll,
        onDeselectOne,
        onPageChange = () => { },
        onRowsPerPageChange,
        onSelectAll,
        onSelectOne,
        page = 1,
        rowsPerPage = 10,
        selected = []
    } = props;

    const selectedSome = selected?.length > 0 && selected.length < items.length;
    const selectedAll = items?.length > 0 && selected.length === items.length;
    const itemsContent = items?.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const onChangePage = (event, value) => onPageChange?.({ page: value + 1 });
    const onChangePageSize = ({ target: { value } }) => onRowsPerPageChange?.({ page: 1, size: value });

    return (
        <Card sx={{ position: 'relative' }}>
            {/* <Scrollbar> */}
            {loading && <CustomLoading />}
            <Box>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            {onSelectAll && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedAll}
                                        indeterminate={selectedSome}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                onSelectAll?.();
                                            } else {
                                                onDeselectAll?.();
                                            }
                                        }}
                                    />
                                </TableCell>
                            )}
                            {columns?.length > 0 && columns?.map((col, index) => (
                                <TableCell key={index}>
                                    {col?.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemsContent?.length > 0 && itemsContent.map((record) => {
                            const isSelected = selected.includes(record[rowKey]);
                            return (
                                <TableRow
                                    hover
                                    key={record[rowKey]}
                                    selected={isSelected}
                                >
                                    {onSelectAll && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={(event) => {
                                                    if (event.target.checked) {
                                                        onSelectOne?.(record[rowKey]);
                                                    } else {
                                                        onDeselectOne?.(record[rowKey]);
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    {columns?.length > 0 && columns?.map((col, index) => (
                                        <TableCell>
                                            {col?.render
                                                ? col.render(record, index)
                                                : col?.dataIndex
                                                    ? record[col.dataIndex] : ''
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            {/* </Scrollbar> */}
            <TablePagination
                component="div"
                count={count}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangePageSize}
                page={page - 1}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage='Filas por página:'
            />
        </Card>
    );
};

const Column = PropTypes.shape({
    title: PropTypes.string,
    render: PropTypes.func,
    dataIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ])
})

CustomTable.propTypes = {
    loading: PropTypes.bool,
    count: PropTypes.number,
    columns: PropTypes.arrayOf(Column),
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    rowKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]).isRequired
};

export default CustomTable;