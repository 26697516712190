// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

let navConfig = [
  // {
  //   title: "home",
  //   path: "/home",
  //   icon: icon("Home"),
  // },
  {
    title: "solicitud de crédito",
    path: "/financing-request/",
    icon: icon("Order"),
  },
  {
    title: "ventas",
    path: "/sales",
    icon: icon("Basket_alt_3"),
  }
];

const associates = [{
  title: "asociados",
  path: "/associates",
  icon: icon("Group"),
}]

export default function getNavConfig(type, viewAssociates) {
  if (type === 0) {
    let newNavConfig = [
      ...navConfig,
      ...associates,
      {
        title: "configuración",
        path: "/settings",
        icon: icon("Filter"),
        subMenu: [
          {
            title: "oficinas",
            path: "/settings/workcenter",
            icon: icon("Chield_alt"),
          },
          {
            title: "productos",
            path: "/settings/products",
            icon: icon("Chield_alt"),
          },
          {
            title: "instaladores",
            path: "/settings/installers",
            icon: icon("User_scan"),
          },
          // {
          //   title: 'productos',
          //   path: '/settings/products',
          //   icon: icon('User_scan'),
          // },
          // {
          //   title: 'comisiones',
          //   path: '/settings/comisions',
          //   icon: icon('User_scan'),
          // }
        ],
      },
      {
        title: "catálogos",
        path: "/catalogs",
        icon: icon("Filter"),
        subMenu: [
          {
            title: "Niveles",
            path: "/catalog/levels",
            icon: icon("User_scan"),
          },
          {
            title: "Calificaciones",
            path: "/catalog/qualifications",
            icon: icon("Sort_list_alt"),
          },
          {
            title: "Productos base",
            path: "/catalog/products",
            icon: icon("Chield_alt"),
          },
          {
            title: "Estados",
            path: "/catalog/state",
            icon: icon("Chield_alt"),
          },
          {
            title: "Ciudades",
            path: "/catalog/city",
            icon: icon("Chield_alt"),
          },
        ],
      },
    ];
    return newNavConfig;
  } else if (type === 1) {
    let newNavConfig = [
      ...navConfig,
      ...associates,
      {
        title: "configuración",
        path: "/settings",
        icon: icon("Filter"),
        subMenu: [
          {
            title: "oficinas",
            path: "/settings/workcenter",
            icon: icon("Chield_alt"),
          },
          {
            title: "productos",
            path: "/settings/products",
            icon: icon("Chield_alt"),
          },
          {
            title: "instaladores",
            path: "/settings/installers",
            icon: icon("User_scan"),
          },
          // {
          //   title: 'productos',
          //   path: '/settings/products',
          //   icon: icon('User_scan'),
          // },
          // {
          //   title: 'comisiones',
          //   path: '/settings/comisions',
          //   icon: icon('User_scan'),
          // }
        ],
      },
    ];
    return newNavConfig;
  } else if (viewAssociates){
    let newNavConfig = [
      ...navConfig,
      ...associates
    ]
    return newNavConfig;
  } else {
    return navConfig;
  }
}

// export default navConfig;
