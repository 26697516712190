import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "", request_full = false, is_revision = true) => {
  try {
    const result = await axiosApi().get(
      `/config/installers/?page=${page}&limit=${limit}&search=${search}&request_full=${request_full}&is_revision=${is_revision}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Instaladores obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/config/installers/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Instalador obtenido satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/config/installers/${id}`);
    return { status: 200, data: result.data, msg: "Instalador eliminado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/config/installers/?installer_ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Instaladores eliminados" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/config/installers", data);
    return { status: 200, data: result.data, msg: "Instalador creado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/config/installers/${id}`, data);
    return { status: 200, data: result.data, msg: "Instalador actualizado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};
