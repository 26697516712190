import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../utils/iconify";
import emailValidator from "email-validator";
import { loginSuccess } from "../redux/userSlice";
import { ServiceLogin } from "../axios/auth";
import { useDispatch } from "react-redux";
import { errorToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";

// ----------------------------------------------------------------------

export default function LoginForm({
  isAdmin
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async (value) => {
    const response = await ServiceLogin(value);
    if (response.status === 200) {
      dispatch(loginSuccess(response.data));
      navigate("/", { replace: true });
    } else {
      errorToast(response.msg);
    }
  };

  const handleUser = async (values) => {
    navigate("/installer/assign", { replace: true });
  }

  const fieldEmail = (
    <Controller
      name="email"
      control={control}
      defaultValue=""
      rules={{
        required: "Correo electrónico es requerido",
        validate: (value) =>
          emailValidator.validate(value) || "Correo electrónico inválido",
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label="Correo electrónico"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      )}
    />
  )

  const fieldsUser = (
    <>
      <Controller
        name="id_user"
        control={control}
        defaultValue=""
        rules={{ required: "ID de usuario es requerido" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="ID de usuario"
            error={!!errors.id_user}
            helperText={errors?.id_user?.message}
          />
        )}
      />
      {fieldEmail}
    </>
  )

  const fieldsAdmin = (
    <>
      {fieldEmail}
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{ required: "Contraseña es requerido" }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        )}
      />
    </>
  )

  return (
    <>
      <form onSubmit={handleSubmit(isAdmin ? handleClick : handleUser)}>
        <Stack spacing={3}>
          {isAdmin ? fieldsAdmin : fieldsUser}
          <LoadingButton
            style={{ marginTop: 25 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!Object.keys(errors).length === 0}
          >
            Ingresar
          </LoadingButton>
        </Stack>
      </form>
      {/* </Stack> */}

      {isAdmin && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <Typography style={{marginBottom:0}} variant="subtitle2" sx={{ mb: 5 }}>
           ¿No tienes cuenta aún? {''}
           <Link variant="subtitle2">¡Regístrate!</Link>
       </Typography> */}
          <Link
            style={{ cursor: "pointer" }}
            href="/security/request-change-password"
          >
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack>
      )}
      <ToastContainer />
    </>
  );
}
