import { SearchOutlined, SettingsOutlined, RefreshOutlined, Settings } from '@mui/icons-material';
import { Card, InputAdornment, OutlinedInput, Stack, Button, Typography, ButtonBase, Chip } from '@mui/material';
import { useMemo } from 'react';
import { deleteFiltersJb } from '../utils/functions';

const CustomSearch = ({
    title = '',
    filters = {},
    listKeys = {},
    onDelete = () => { },
    onReset = () => { },
    onOpen = () => { }
}) => {

    const discard = ['page', 'size'];

    const params = useMemo(() => {
        let params = filters || {};
        let result = deleteFiltersJb({ obj: params, listDelete: discard });
        return Object.entries(result || {});
    }, [filters]);

    const handleDelete = (key) => {
        let results = deleteFiltersJb({ obj: filters, listDelete: [key] });
        onDelete(results)
    }

    return (
        <>
            <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={1}>
                <Typography variant="h4">
                    {title}
                </Typography>
                <Stack direction='row' spacing={1}>
                    <Button variant='contained' size='small' onClick={() => onReset()}>
                        <RefreshOutlined />
                    </Button>
                    <Button variant='contained' onClick={() => onOpen()}>
                        <Settings />
                    </Button>
                </Stack >
            </Stack >
            {params?.length > 0 && (
                <Stack
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    flexWrap='wrap'
                    sx={{ mb: 2, gap: 1 }}
                >
                    {params?.map((item, idx) => {
                        let record = listKeys[item[0]];
                        return (
                            <Chip
                                key={idx}
                                size='small'
                                label={`${record?.name ? record?.name : item[0]}: ${record?.get ? record?.get(item[1]) : item[1]}`}
                                onDelete={() => handleDelete(item[0])}
                            />
                        )
                    })}
                </Stack>
            )}
        </>
    )
}

export default CustomSearch;
