import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import { GetData as GetDataProducts } from "../../../axios/config/productbyoffice";
import { GetDataItemForm as GetDataQualifications } from "../../../axios/catalog/qualification";
import { GetDataStatus } from "../../../axios/sales";
import { GetDataForm as GetDataOffice } from "../../../axios/config/workcenter";
import { CreateData as createSale} from "../../../axios/sales"

const SalesVendorForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  dataLevel = [],
  // dataOffice = []
  level_order
}) => {
  const [formData, setFormData] = useState({
    vendor_id: "",
    office_id: "",
    director: "",
    product_by_office_id: "",
    original_selling_price: "",
    first_name_client: "",
    middle_name_client: "",
    last_name_client: "",
    address_client: "",
    city_client: "",
    state_client: "",
    zip_code_client: "",
    email_client: "",
    cel_phone_client: "",
    home_phone_client: "",
    notes_for_installer: ""
  });
  const [dataVendor, setDataVendor] = useState([])
  const [dataProducts, setDataProducts] = useState([])
  const [dataQualification, setDataQualification] = useState([])
  const [disabledJustification, setDisabledJustification] = useState(true)
  const [statusList, setStatusList] = useState([])
  const [dataOffice, setDataOffice] = useState([])

  const user = useSelector((state) => state.user.user.dataBasic);

  useEffect(() => {
    if (openEdit) {
      dataFillEdit();
    }else{
      dataFillNew()
    }
  }, [open]);

  // useEffect(() => {
  //   if (formData.product_by_office_id != ""){
  //     let productCurrent = dataProducts.find(item => item.id === formData.product_by_office_id);
  //     if (productCurrent){
  //       setFormData({...formData, price_original: productCurrent.price})
  //     }else{
  //       setFormData({...formData, price_original: ""})
  //     }
  //   }else{
  //     setFormData({...formData, price_original: ""})
  //   }

  // }, [formData.product_by_office_id])

  // useEffect(() =>{
  //   if(formData.price_original !== ""){
  //     if (formData.price_original > formData.amount_financed){
  //       setDisabledJustification(false)
  //     }else{
  //       setDisabledJustification(true)
  //     }
  //   }
  // }, [formData.amount_financed])

  // useEffect(() => {
  //   if (user.level_order == 1){
  //     getDataOfficeAxios()
  //   }else{

  //   }
  // }, []);

  useEffect(() =>{
    dataFillNew()
  }, []);

  // useEffect(() => {
  //   // if (user.level_order == 1){
  //   //   getDataOfficeAxios()
  //   // }else{

  //   // }
  //   // console.log("///////")
  //   // console.log(data)
  //   // console.log("---------")
  //   // console.log(user)
  // }, [data]);

  // useEffect(() => {
  //   // console.log("////////////////////")
  //   // console.log(user)
  //   if (openEdit){
  //     alert("Se abrió para editar")
  //   }else{
  //     alert("Se abrio para nuevo")
  //     // if (user.office_id){

  //     // }else{
  //     //   getDataOfficeAxios()
  //     // }
  //   }
  //   getDataOfficeAxios()
  // }, [open]);

  // const getDataOfficeAxios = async ()=>{
  //   let dataOfficesBack = await GetDataOffice()
  //   if (dataOfficesBack.status == 200){
  //     console.log("////////")
  //     console.log(dataOfficesBack)
  //     setDataOffice(dataOfficesBack.data.data)
  //   }
  // }

  const dataFillNew = async() =>{
    // setDataVendor([{ id: user.profile_id, name: user.name }]);
    // setDataOffice([{id: user.office_id, name: `${user.office.name} ,${user.office.city?.name} ,${user.office.state?.name}`}])
    
    await request_async_base()
    // setFormData({
    //   ...formData,
    //   vendor_id: user.profile_id,
    //   office_id: user.office_id,
    //   director: user.office?.owner ? `${user.office.owner.first_name} ${user.office.owner.last_name}` : "",
    //   status: "init"
    // })

  }

  const request_async_base = async()=>{
    let products = await GetDataProducts(undefined,undefined,undefined,true)
    if (products.status == 200){
      setDataProducts(products.data.data)
    }
  }

  //   let qualifications = await GetDataQualifications()
  //   if (qualifications.status == 200){
  //     setDataQualification(qualifications.data.data)
  //   }

  //   let statusList = await GetDataStatus()
  //   if (statusList.status == 200){
  //     setStatusList(statusList.data.data)
  //   }
  // }

  const dataFillEdit = async() => {
    await request_async_base()
    // setDataVendor([{ id: dataEdit.vendor_id, name: `${dataEdit.vendor.first_name} ${dataEdit.vendor.last_name}` }]);
    // setDataOffice([{id: dataEdit.vendor.office.id, name: `${dataEdit.vendor.office.name} ,${dataEdit.vendor.office.city} ,${dataEdit.vendor.office.state}`}])
    setFormData({
      vendor_id: dataEdit.vendor_id,
      product_by_office_id: dataEdit.product_by_office_id,
      original_selling_price: dataEdit.original_selling_price,
      invoice: dataEdit.invoice ? dataEdit.invoice : "",
      qualification_id: dataEdit.qualification_id ? dataEdit.qualification_id : "",
      status_sale: dataEdit.status_sale,
      first_name_client: dataEdit.first_name_client,
      middle_name_client: dataEdit.middle_name_client,
      last_name_client: dataEdit.last_name_client,
      address_client: dataEdit.address_client,
      city_client: dataEdit.city_client,
      state_client: dataEdit.state_client,
      zip_code_client: dataEdit.zip_code_client,
      email_client: dataEdit.email_client,
      cel_phone_client: dataEdit.cel_phone_client,
      home_phone_client: dataEdit.home_phone_client,
      notes_for_installer: dataEdit.notes_for_installer
    });
  };

  const handleChange = (event) => {
    if (event.target.name == 'product_by_office_id'){
      let productCurrent = dataProducts.find(item => item.id === event.target.value);
      setFormData({ ...formData, [event.target.name]: event.target.value, original_selling_price: productCurrent.price, product_by_office_id: productCurrent.id });
    }else{
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async () => {
    let new_data ={...formData, director: formData.director !== "" ?formData.director:user.director_id, office_id: formData.office_id !== "" ?formData.office_id:user.office_id, vendor_id: formData.vendor_id !== "" ?formData.vendor_id:user.profile_id}
    if (openEdit) {
      await editItemFunction(new_data, dataEdit.id);
    } else {
      let data2 = {...new_data, status_sale: "1"}
      await createItem(data2);
    }
    onClose();
  };
  //////////////////////////////////////////
  
  /////////////////////////////////////////

  return (
    <Grid>
        {/* -------------------------- Apartado de información de venta --------------------------------*/}
        <Grid elevation={3} style={{ padding: 20 }} sx={{
          maxHeight: "90vh",
          position: "absolute",
          overflowY: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          "& > *": {
            // marginBottom: '16px', // Separación vertical uniforme entre elementos
            width: "100%", // Hacer que los elementos ocupen todo el ancho del modal
          },
        }}>
          <Grid container spacing={2}>
            {/* ------------- Inicia datos del vendedor -----------------*/}
            {/* {user?.level_order < 2 && <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Información del vendedor
              </Typography>
              <Divider />
            </Grid>}
            {user?.level_order < 2 && <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Vendedor"
                name="vendor_id"
                value={formData.vendor_id}
                fullWidth
                select
                // disabled = {!data.select_disabled}
              >
                {dataVendor.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </TextField>
            </Grid>}
            {user?.level_order < 2 && <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Oficina"
                name="office_id"
                value={data.office_id}
                fullWidth
                select
                // disabled = {!data.select_disabled}
              >
                {data?.list_office && data?.list_office.map((item) => {
                  return <MenuItem value={item.id}>{item?.name}</MenuItem>;
                })}
              </TextField>
            </Grid>}
            {user?.level_order == 0 && <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Director"
                name="director"
                value={formData.director}
                fullWidth
                disabled
              />
            </Grid>} */}
            {/* ------------- Termina datos del vendedor -----------------*/}
            {/* ------------- Inicia datos del producto -----------------*/}
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Información del producto
              </Typography>
              <Divider />
            </Grid>
            {/* {
              user?.level_order < 2 &&
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  fullWidth
                  label="Oficina"
                  name="office_id"
                  select
                  // disabled={!data.select_disabled}
                  value={data.office_id}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                >
                  {data?.list_office && data.list_office.map((item) => {
                    return <MenuItem value={item.id}>{item.name}, {item.city.name}, {item.state.name}</MenuItem>;
                  })}
                </TextField>
              </Grid>
            } */}
            <Grid item xs={12} sm={12} md={10}>
              <TextField
                label="Producto"
                name="product_by_office_id"
                value={formData.product_by_office_id}
                onChange={(item)=>handleChange(item)}
                fullWidth
                select
                >
                {dataProducts.map((item) => {
                  return <MenuItem value={item.id}>{item?.product?.name}</MenuItem>;
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                label="Precio"
                name="original_selling_price"
                value={formData.original_selling_price}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Datos de cliente
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Primer nombre"
                name="first_name_client"
                value={formData.first_name_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Segundo nombre"
                name="middle_name_client"
                value={formData.middle_name_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Apellidos"
                name="last_name_client"
                value={formData.last_name_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Datos de ubicación
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Dirección de instalación"
                name="address_client"
                value={formData.address_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label="Ciudad"
                name="city_client"
                value={formData.city_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                label="Estado"
                name="state_client"
                value={formData.state_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                label="Código postal"
                name="zip_code_client"
                value={formData.zip_code_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Datos de contacto
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Correo electrónico"
                name="email_client"
                value={formData.email_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Celular"
                name="cel_phone_client"
                value={formData.cel_phone_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                label="Teléfono de casa"
                name="home_phone_client"
                value={formData.home_phone_client}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Notas al instalador"
                name="notes_for_installer"
                value={formData.notes_for_installer}
                onChange={(item)=>handleChange(item)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} style={{textAlign:'center'}}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {openEdit ? "Actualizar" : "Guardar"}
        </Button>
        </Grid>
        <br />
    </Grid>
  );
};


export default SalesVendorForm;