import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@mui/material";
// component
import Iconify from "../../utils/iconify";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  "&.Mui-focused": {
    // width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  titleCustom,
  handleClic,
  search,
  setSearch,
  setOpenConfirmationDialog,
}) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {numSelected === 1 ? "seleccionado" : "seleccionados"}
        </Typography>
      ) : (
        <div>
          <StyledSearch
            value={filterName}
            onChange={onFilterName}
            placeholder={`Buscar ${titleCustom}...`}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
          {filterName && (
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => handleClic()}
              variant="contained"
            >
              Buscar
            </Button>
          )}
          {search && (
            <Button
              style={{ marginLeft: "10px" }}
              onClick={() => {
                handleClic("");
                setSearch("");
              }}
              variant="contained"
            >
              Quitar filtro
            </Button>
          )}
        </div>
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={() => setOpenConfirmationDialog(true)}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
