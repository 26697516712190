import React, { useState } from 'react';
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material';

const CustomSelect = ({
    value = null,
    size = 'small',
    label = '',
    keyValue = '',
    keyLabel = '',
    width = 'auto',
    options = [],
    disabled = false,
    onChange = () => { }
}) => {

    const [val, setVal] = useState(null);

    const handleChange = (event) => {
        setVal(event?.target?.value)
        onChange(event?.target?.value)
    }

    return (
        <FormControl disabled={disabled} sx={{ width }} size={size}>
            <InputLabel id='custom-select-value'>
                {/* {label} */}
            </InputLabel>
            <Select
                size={size}
                labelId='custom-select-value'
                id='custom-select-value'
                value={value || val}
                label={label}
                onChange={handleChange}
            >
                {options?.length > 0 && options?.map((record, index) => (
                    <MenuItem key={index} value={record[keyValue]}>
                        {record[keyLabel]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CustomSelect