import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { TableRow, TableCell, MenuItem, Stack } from '@mui/material';
import { GetDataForm as GetLevels } from "../../../axios/catalog/levels";
import { GetDataItemForm as GetQualification } from "../../../axios/catalog/qualification";
import { useForm, Controller } from "react-hook-form";
import { GetDataForm as GetDataOffices, GetDataFormOwnerProfile  } from "../../../axios/config/workcenter";
import { GetData as GetDataProducts } from "../../../axios/catalog/products";
import {
  GetDataOrder
} from "../../../axios/recommended";

const LevelCatalogModalForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  level_order
}) => {

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState({
    product_id: null,
    office_id: null,
    owner_id: "",
    price: "",
  });
  const [dataHeaders, setDataHeaders] = useState([])
  const [dataRows, setDataRows] = useState([])
  const [dataOffice, setDataOfice] = useState([])
  const [dataProduct, setDataProduct] = useState([])
  const [dataOwners, setDataOwners] = useState([])

  useEffect(() => {
    if (openEdit) {
      setValue("product_id", dataEdit.product.id)
      setValue("office_id", dataEdit.office.id)
      setValue("owner_id", dataEdit.owner_id)
      setValue("price", dataEdit.price)
      getListOffice(dataEdit.owner_id)
    }
  }, [openEdit]);

  useEffect(() => {
    if (open) {
      getLevels()
    }
  }, [open]);

  const getListOffice = async(id)=>{
    let office_own = await GetDataFormOwnerProfile(id)
    if (office_own.status == 200){
      setDataOfice(office_own.data.data)
    }
  }

  const getLevels = async () =>{
    let directors_list = await GetDataOrder()
    if (directors_list.status == 200){
      setDataOwners(directors_list.data)
    }
    if (level_order !== 0){
      let office_own = await GetDataOffices()
      if (office_own.status == 200){
        setDataOfice(office_own.data.data)
      }
    }
    let product_own = await GetDataProducts(1, 50, "", true)
    if (product_own.status == 200){
      setDataProduct(product_own.data.data)
    }
    
    let infoLevels = await GetLevels(false, true);
    if (infoLevels.status === 200) {
      setDataRows(infoLevels.data.data);
    }
    let infoQualification = await GetQualification();
    if (infoQualification.status === 200) {
      setDataHeaders(infoQualification.data.data);
    }
    for (const qualif of infoQualification.data.data){
      for (const lev of infoLevels.data.data){
        if (!openEdit){
          setValue(`${qualif.id}-${lev.id}`, "0.00")
        }else{
          setValue(`${qualif.id}-${lev.id}`, dataEdit.commissions[qualif.id][lev.id])
        }
      }
    }
    
  }

  const TableHeader = ({ headers }) => {
    return (
      <TableRow>
        <TableCell><b>Comisiones</b></TableCell>
        {headers.map((header) => (
          <TableCell style={{textAlign:'center'}} key={header.id}>{header.name}</TableCell>
        ))}
      </TableRow>
    );
  };

  const TableRowComponent = ({ row, headers, onCellValueChange }) => {
    return (
      <TableRow>
        <TableCell>{row.name}</TableCell>
        {headers.map((header, index) => (
          <TableCell key={header.id}>
            <Controller
              name={`${header.id}-${row.id}`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  // type="number"
                />
              )}
            />
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const handleClick = async (value) => {
    let dict_result = {}
    for (const qualif of dataHeaders){
      dict_result[qualif.id] = {}
      for (const lev of dataRows){
        let new_value = getValues(`${qualif.id}-${lev.id}`)
        if (new_value){
          dict_result[qualif.id][lev.id] = parseFloat(getValues(`${qualif.id}-${lev.id}`))
        }else{
          dict_result[qualif.id][lev.id] = 0.00
        }
      }
    }
    const response = {
      "product_id": value.product_id,
      "office_id": value.office_id,
      "price": parseFloat(value.price),
      "commissions": dict_result,
      "owner_id": value.owner_id
    }
    if (openEdit){
      await editItemFunction(response, dataEdit.id)
    }else{
      await createItem(response)
    }

    onClose();

  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          maxHeight: "80vh",
          overflowY: "auto",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1200,
          bgcolor: "background.paper",
          // boxShadow: 24,
          p: 4,
          // bgcolor: 'white', // Color de fondo del modal
          borderRadius: "10px", // Redondear bordes
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)", // Sombra
          padding: "20px", // Espaciado interno
        }}
      >
        <h2>
          {openEdit ? "Editar" : "Crear"} {titleCustom?.toLowerCase()}
        </h2>
        <form onSubmit={handleSubmit(handleClick)}>
        {/* <TextField
          fullWidth
          label="Producto"
          name="product_id"
          select
          value={formData.product_id}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          {dataProduct.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        <TextField
          fullWidth
          label="Oficina"
          name="office_id"
          select
          value={formData.office_id}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          {dataOffice.map((item) => {
            return <MenuItem value={item.id}>{`${item.name}, ${item.city}, ${item.state}`}</MenuItem>;
          })}
        </TextField> */}
        <Stack spacing={3}>
        <Controller
            name="product_id"
            control={control}
            style={{margin: 10}}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Producto"
              >
                {dataProduct.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </TextField>
            )}
          />

        { level_order === 0 ?
          <Controller
          name="owner_id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Director"
              select
              onChange={(e)=>{
                setValue("office_id", null)
                setValue("owner_id", e.target.value)
                getListOffice(e.target.value)
              }}
            >
              {dataOwners.map((item) => {
                return <MenuItem value={item.id}>{`${item.first_name} ${item.last_name}`}</MenuItem>;
              })}
            </TextField>
            )}
          />:null
        }
        <Controller
            name="office_id"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Oficina"
              >
                {dataOffice.map((item) => {
                  return <MenuItem value={item.id}>{`${item.name}, ${item.city?.name? item.city.name: ""}, ${item.state?.name?item.state.name:""}`}</MenuItem>;
                })}
              </TextField>
            )}
          />
        <Controller
            name="price"
            control={control}
            rules={{
              required: "Precio es requerido"
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Precio"
                error={!!errors.price}
                helperText={errors.price?.message}
              />
            )}
          />
          </Stack>
        <table>
        <TableHeader headers={dataHeaders} />
          {dataRows.map((row) => (
            <TableRowComponent
              key={row.id}
              row={row}
              headers={dataHeaders}
            />
          ))}
        </table>
        <Button type="submit" variant="contained" color="primary">
          {openEdit ? "Editar" : "Crear"}
        </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default LevelCatalogModalForm;
