import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "") => {
  try {
    const result = await axiosApi().get(
      `/catalog/city/?page=${page}&limit=${limit}&search=${search}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Ciudades obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataForm = async (id) => {
  try {
    const result = await axiosApi().get(
      `/catalog/city/form/full/${id}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Ciudades obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/catalog/city/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Ciudad obtenida satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/catalog/city/${id}`);
    return { status: 200, data: result.data, msg: "Ciudad eliminada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/catalog/city/?ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Ciudades eliminadas" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/catalog/city", data);
    return { status: 200, data: result.data, msg: "Ciudad creada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/catalog/city/${id}`, data);
    return { status: 200, data: result.data, msg: "Ciudad actualizada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};
