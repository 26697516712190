import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { MenuItem } from '@mui/material';
import {
  GetDataForm
} from "../../../axios/catalog/state";

const DefaultModalForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    state_id: ""
  });
  const [dataStates, setDataStates] = useState([])

  useEffect(() => {
    if (openEdit) {
      setFormData({
        name: dataEdit.name,
        state_id: dataEdit.state_id
      });
    }
  }, [openEdit]);

  useEffect(() => {
    if (open) {
      getStates()
    }
  }, [open]);

  const getStates = async () =>{
    let states_list = await GetDataForm()
    if (states_list.status == 200){
      setDataStates(states_list.data.data)
    }
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    // En este punto, puedes enviar los datos del formulario a tu servidor o realizar otras acciones.
    if (openEdit) {
      await editItemFunction(formData, dataEdit.id);
    } else {
      await createItem(formData);
    }
    onClose(); // Cierra el modal después de enviar el formulario.
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          // boxShadow: 24,
          p: 4,
          // bgcolor: 'white', // Color de fondo del modal
          borderRadius: "10px", // Redondear bordes
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)", // Sombra
          padding: "20px", // Espaciado interno
        }}
      >
        <h2>
          {openEdit ? "Editar" : "Crear"} {titleCustom?.toLowerCase()}
        </h2>
        <TextField
          name="name"
          label="Nombre"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          fullWidth
          label="Estado"
          name="state_id"
          select
          value={formData.state_id}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          {dataStates.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {openEdit ? "Actualizar" : "Crear"}
        </Button>
      </Box>
    </Modal>
  );
};

export default DefaultModalForm;
