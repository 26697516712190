import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  GetDataForm as GetStatesApi
} from "../../axios/catalog/state";
import {
  GetDataForm as GetCitiesApi
} from "../../axios/catalog/city";
import { MenuItem } from "@mui/material";
import {
  GetDataOrder
} from "../../axios/recommended";

const WorkcenterModalForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  level_order
}) => {
  const [formData, setFormData] = useState({
    name: "",
    city_id: "",
    state_id: "",
    owner_id: "",
  });
  const [dataStates, setDataStates] = useState([])
  const [dataCities, setDataCities] = useState([])
  const [dataOwners, setDataOwners] = useState([])

  useEffect(() => {
    if (openEdit) {
      setFormData({
        name: dataEdit.name,
        city_id: dataEdit.city_id,
        state_id: dataEdit.state_id,
        owner_id: dataEdit.owner_id,
      });
      getCities(dataEdit.state_id)
    }
  }, [openEdit]);

  useEffect(() => {
    if (open) {
      getStates()
    }
  }, [open]);

  const getStates = async () =>{
    let states_list = await GetStatesApi()
    if (states_list.status == 200){
      setDataStates(states_list.data.data)
    }
    let director_list = await GetDataOrder()
    if (director_list.status == 200){
      setDataOwners(director_list.data)
    }
  }

  const getCities = async (id) =>{
    let cities_list = await GetCitiesApi(id)
    if (cities_list.status == 200){
      setDataCities(cities_list.data.data)
    }
  }

  const handleChange = async (event) => {
    if (event.target.name == 'state_id'){
      setFormData({
        ...formData,
        city_id : "",
        [event.target.name]: event.target.value
      })
      await getCities(event.target.value)
    }else{
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async () => {
    // En este punto, puedes enviar los datos del formulario a tu servidor o realizar otras acciones.
    if (openEdit) {
      await editItemFunction(formData, dataEdit.id);
    } else {
      await createItem(formData);
    }
    onClose(); // Cierra el modal después de enviar el formulario.
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          // boxShadow: 24,
          p: 4,
          // bgcolor: 'white', // Color de fondo del modal
          borderRadius: "10px", // Redondear bordes
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)", // Sombra
          padding: "20px", // Espaciado interno
        }}
      >
        <h2>
          {openEdit ? "Editar" : "Crear"} {titleCustom?.toLowerCase()}
        </h2>
        <TextField
          name="name"
          label="Nombre"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="state_id"
          label="Estado"
          value={formData.state_id}
          select
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {dataStates.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        <TextField
          name="city_id"
          label="Ciudad"
          value={formData.city_id}
          onChange={handleChange}
          select
          fullWidth
          margin="normal"
        >
          {dataCities.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
        </TextField>
        { level_order === 0 ?
          <TextField
          name="owner_id"
          label="Director"
          value={formData.owner_id}
          select
          onChange={handleChange}
          fullWidth
          margin="normal"
        >
          {dataOwners.map((item) => {
            return <MenuItem value={item.id}>{`${item.first_name} ${item.last_name}`}</MenuItem>;
          })}
        </TextField>:null
        }
        <Button variant="contained" color="primary" onClick={handleSubmit}>
        {openEdit ? "Editar" : "Crear"}
        </Button>
      </Box>
    </Modal>
  );
};

export default WorkcenterModalForm;
