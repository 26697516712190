import { useState, useEffect } from "react";
import TableCustom from "../components/table-custom";
import ModalAddEdit from "../components/forms/recommendedForm";
import {
  GetData,
  DeleteData,
  DeleteDataMassive,
  CreateData,
  GetDataItem,
  EditData
} from "../axios/recommended";
import { GetDataForm as GetDataLevel } from "../axios/catalog/levels";
import { GetData as GetDataOffice } from "../axios/config/workcenter";
import { successToast, errorToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

export default function RecommendedPage() {
  //////////////////////////// Datos estáticos ////////////////////////////
  const TABLE_HEAD = [
    { id: "first_name", label: "Nombre", alignRight: false },
    { id: "last_name", label: "Apellidos", alignRight: false },
    { id: "email", label: "email", alignRight: false },
    { id: "phone", label: "Teléfono", alignRight: false },
    { id: "number_of_sales", label: "Número de ventas", alignRight: false },
    // { id: 'address_1', label: 'Dirección 1', alignRight: false },
    // { id: 'address_2', label: 'Dirección 2', alignRight: false },
    // { id: 'city', label: 'Ciudad', alignRight: false },
    // { id: 'state', label: 'Estado', alignRight: false },
    // { id: 'zip_code', label: 'C.P.', alignRight: false },
    { id: "level_id", label: "Nivel", alignRight: false },
    { id: "office", label: "Oficina", alignRight: false },
  ];

  //////////////////////////// Definición de estados ////////////////////////////
  const [openForm, setOpenForm] = useState(false);
  const [openFormEdit, setOpenFormEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  const [listLevel, setListLevel] = useState([]);
  const [listOffice, setListOffice] = useState([]);
  const [editLevelPermission, setEditLevelPermission] = useState(false)

  //////////////////////////// Manejo de estados ////////////////////////////
  const user = useSelector((state) => state.user.user.dataBasic);
  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [page]);

  useEffect(() => {
    if (user.level_order <= 1){
      setEditLevelPermission(true)
    }
  }, [user]);

  useEffect(() => {
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  }, [rowsPerPage]);

  const handleFind = (value) => {
    setSearch(value);
    if (page === 1) {
      getInfo(value);
    } else {
      setPage(1);
    }
  };

  const editItem = async (id) => {
    let infoLevels = await GetDataLevel(true);
    if (infoLevels.status === 200) {
      setListLevel(infoLevels.data.data);
    }
    setOpenFormEdit(true);
    let dataItem = await GetDataItem(id);
    if (dataItem.status === 200) {
      setDataEdit(dataItem.data);
    }
    setOpenForm(true);
  };

  //////////////////////////// Consumo de backend ////////////////////////////
  const getInfo = async (search_force) => {
    let info;
    let infoOffices = await GetDataOffice(undefined, undefined, undefined, true, true);
    if (user.level_order == 1){
      if (infoOffices.status === 200) {
        let newListOffice = infoOffices.data.data
        setListOffice(newListOffice);
      }
    }
    if (search_force) {
      info = await GetData(page, rowsPerPage, search_force, userId);
    } else {
      if (search_force === "") {
        info = await GetData(page, rowsPerPage, "", userId);
      } else {
        info = await GetData(page, rowsPerPage, search, userId);
      }
    }
    if (info.status === 200) {
      let newInfo = info.data.data.map((item) => ({
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        number_of_sales: item.number_of_sales,
        // address_1: item.address_1,
        // address_2: item.address_2,
        // city:item.city,
        // state: item.state,
        // zip_code: item.zip_code,
        level_id: item.level.name,
        office: item.office?.name?`${item.office.name}, ${item.office.city?.name?item.office.city.name:""}, ${item.office.state?.name?item.office.state.name:""}`:""
      }));
      setDataList(newInfo);
      setTotalData(info.data.total_records);
    }
  };

  const createItem = async (data) => {
    let newData = {
      ...data,
      birthday: data.birthday ? data.birthday.toISOString().split("T")[0] : null,
    };
    if (newData.office_id == ""){
      delete newData.office_id
    }
    let createData = await CreateData(newData);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const editItemFunction = async (data, id) => {
    let newData = {
      ...data,
      birthday: data.birthday? data.birthday.toISOString().split("T")[0]: null,
    };
    let createData = await EditData(newData, id);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const handleActiveFormCreate = async () =>{
    let infoLevels = await GetDataLevel();
    if (infoLevels.status === 200) {
      setListLevel(infoLevels.data.data);
    }
    setOpenForm(true)
  }

  const deleteItem = async (element) => {
    let deletedData;
    if (Array.isArray(element)) {
      deletedData = await DeleteDataMassive(element);
    } else {
      deletedData = await DeleteData(element);
    }
    if (deletedData.status === 200) {
      successToast(deletedData.msg);
    } else {
      errorToast(deletedData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  //////////////////////////// Render ////////////////////////////
  return (
    <>
      {/* <Helmet>
        <title> User | Minimal UI </title>
      </Helmet> */}

      <TableCustom
        dataList={dataList}
        dataHeader={TABLE_HEAD}
        titleCustom="Asociados"
        titleCustomPlural={
          search !== ""
            ? `Asociados (filtrados por '${search}')`
            : "Asociados"
        }
        includeCreate={true}
        includeCheck={true}
        includeActions={true}
        includePhoto={false}
        page={page}
        totalData={totalData}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        deleteItem={deleteItem}
        setOpenForm={handleActiveFormCreate}
        editItem={editItem}
        handleFind={handleFind}
        order_by_default="name"
        type_order_by_default="asc"
      />
      {openForm && (
        <ModalAddEdit
          dataLevel={openFormEdit?editLevelPermission?listLevel:[]:listLevel}
          dataOffice={user.level_order == 1?listOffice:[]}
          dataEdit={dataEdit}
          editItemFunction={editItemFunction}
          createItem={createItem}
          open={openForm}
          openEdit={openFormEdit}
          onClose={() => {
            setOpenForm(false);
            setOpenFormEdit(false);
          }}
          titleCustom={"Asociado"}
        />
      )}
      <ToastContainer />
    </>
  );
}
