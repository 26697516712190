import React, { useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Box,
    Toolbar,
    Paper,
    Typography,
    ButtonBase,
    Stack,
    Avatar,
    Popper,
    Card,
    ClickAwayListener,
    CardContent,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Transitions from '../../components/transitions';
import { LogoutOutlined } from '@mui/icons-material';
import { ToastContainer } from 'react-toastify';

const avatarImg = 'https://img.freepik.com/vector-premium/avatar-hombre-sonriente-joven-hombre-bigote-barba-marron-cabello-sueter-amarillo-o-sudadera-ilustracion-personaje-personas-vector-3d-estilo-minimalista-dibujos-animados_365941-860.jpg';

const Layout = () => {
    return (
        <>
            <ToastContainer />
            <AppBar
                position='fixed'
                color='inherit'
                elevation={0}
                sx={{
                    borderBottom: `1px solid rgb(240, 240, 240)`
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Box component='main' sx={{ flex: 1, p: 3 }}>
                <Outlet />
            </Box>
        </>
    )
}

const Header = () => {
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            flex={1}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
        >
            <img
                alt='logo'
                height={!matchMD ? 40 : 30}
                src='/assets/logo.png'
                style={{ objectFit: 'contain' }}
            />
            <Profile matchMD={matchMD} />
        </Box>
    )
}

const Profile = ({
    matchMD
}) => {

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClose = (event) => {
        if (anchorRef.current?.contains(event?.target)) return;
        setOpen(false)
    }

    return (
        <>
            <ButtonBase
                ref={anchorRef}
                sx={{ borderRadius: 10, pr: 0.5 }}
                onClick={() => setOpen(prev => !prev)}
            >
                <Stack direction='row' spacing={1} alignItems='center' sx={{ p: 0.5 }}>
                    <Avatar alt='profile user' src={avatarImg} sx={{ width: 32, height: 32 }} />
                    {!matchMD && (
                        <Typography variant='subtitle1'>Antonio Hau</Typography>
                    )}
                </Stack>
            </ButtonBase>
            <Popper
                placement='bottom-end'
                open={open}
                role={undefined}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 12]
                            }
                        }
                    ]
                }}

            >
                {({ TransitionProps }) => (
                    <Transitions type='fade' in={open} {...TransitionProps}>
                        {open && (
                            <Paper
                                sx={{
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px',
                                    width: 250,
                                    maxWidth: 250
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Card elevation={0}>
                                        <CardProfile />
                                    </Card>
                                </ClickAwayListener>
                            </Paper>
                        )}
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

const CardProfile = () => {
    const navigate = useNavigate();
    return (
        <>
            <CardContent sx={{ padding: 1 }}>
                <Grid container justifyContent='space-between' alignItems='center' sx={{ background: 'white' }}>
                    <Grid item>
                        <Stack direction='row' spacing={1.25} alignItems='center'>
                            <Avatar alt='profile user' src={avatarImg} sx={{ width: 32, height: 32 }} />
                            <Stack>
                                <Typography sx={{ fontSize: '0.875rem', fontWeight: 400 }}>
                                    Antonio Hau
                                </Typography>
                                <Typography sx={{ fontSize: '0.75rem', fontWeight: 400, color: 'rgb(140, 140, 140)' }}>
                                    Instalador
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            <Box sx={{ borderTop: 1, borderTopColor: 'rgb(240, 240, 240)' }}>
                <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: 'rgb(140, 140, 140)' }, '& .MuiTypography-root': { fontSize: '0.875rem' } }}>
                    <ListItemButton onClick={() => navigate('/security/installer/login', { replace: true })}>
                        <ListItemIcon>
                            <LogoutOutlined />
                        </ListItemIcon>
                        <ListItemText primary='Cerrar sesión' />
                    </ListItemButton>
                </List>
            </Box>
        </>
    )
}

export default Layout