import React from 'react';
import CustomModal from '../CustomModal';
import { Stack, Typography } from '@mui/material';

const Field = ({
    title = '',
    description = ''
}) => {
    return (
        <p style={{ margin: 0 }}>{title}: <span style={{ color: 'gray' }}>{description}</span></p>
    )
}
const ModalDetail = ({
    visible = false,
    itemDetail = {},
    onClose = () => { }
}) => {

    return (
        <CustomModal
            visible={visible}
            title='Detalle de la orden'
            onClose={onClose}
        >
            <Field title='ID orden' description={itemDetail?.id} />
            <Field title='Dirección' description={itemDetail?.detail?.address} />
            {itemDetail?.detail?.comment && (
                <Field title='Comentario' description={itemDetail?.detail?.comment} />
            )}
        </CustomModal>
    )
}

export default ModalDetail