import { useState, useEffect } from "react";
import TableCustom from "../components/table-custom";
import ModalAddEdit from '../components/forms/salesForm2'
import {
  GetData,
  DeleteData,
  CreateData,
  GetDataItem,
  EditData,
  EditDataAdmin,
  DeleteDataMassive,
} from "../axios/sales";
import { successToast, errorToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { statusSaleList } from "../utils/constants";

export default function SalesPage() {
  //////////////////////////// Datos estáticos ////////////////////////////
  const TABLE_HEAD = [
    { id: "invoice", label: "Código", alignRight: false },
    { id: "product", label: "Producto", alignRight: false },
    { id: "office", label: "Oficina", alignRight: false },
    { id: "vendor", label: "Vendedor", alignRight: false },
    { id: "qualification", label: "Calificación", alignRight: false },
    { id: "status_sale", label: "Estatus de venta", alignRight: false }
  ];
  const dataBasic = useSelector((state) => state.user.user.dataBasic);

  //////////////////////////// Definición de estados ////////////////////////////
  const [openForm, setOpenForm] = useState(false);
  const [openFormEdit, setOpenFormEdit] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);

  //////////////////////////// Manejo de estados ////////////////////////////
  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInfo();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  }, [rowsPerPage]);

  const handleFind = (value) => {
    setSearch(value);
    if (page === 1) {
      getInfo(value);
    } else {
      setPage(1);
    }
  };

  const editItem = async (id) => {
    setOpenFormEdit(true);
    let dataItem = await GetDataItem(id);
    if (dataItem.status === 200) {
      setDataEdit(dataItem.data);
    }
    setOpenForm(true);
  };

  //////////////////////////// Consumo de backend ////////////////////////////
  const getInfo = async (search_force) => {
    let info;
    console.log("mira esto....")
    console.log(dataBasic)
    if (search_force) {
      info = await GetData(page, rowsPerPage, search_force, dataBasic.user_id);
    } else {
      if (search_force === "") {
        info = await GetData(page, rowsPerPage, undefined, dataBasic.user_id);
      } else {
        info = await GetData(page, rowsPerPage, search, dataBasic.user_id);
      }
    }
    if (info.status === 200) {
      let newInfo = info.data.data.map((item) => ({
        id: item.id,
        invoice: item.invoice,
        product: item.product_by_office?.product?.name ? item.product_by_office.product.name: "",
        office: item.product_by_office?.office?.name ?`${item.product_by_office.office.name}, ${item.product_by_office.office.city.name}, ${item.product_by_office.office.state.name}`:"",
        vendor: item.vendor?.first_name?`${item.vendor.first_name} ${item.vendor.last_name}`:"",
        qualification: item.qualification_id? item.qualification.name:"",
        status_sale: item.status_sale? statusSaleList.find(e=>e.id == item.status_sale).name:"",
      }));
      setDataList(newInfo);
      setTotalData(info.data.total_records);
    }
  };

  const createItem = async (data) => {
    // let newData = {
    //   ...data,
    //   birthday: data.birthday.toISOString().split("T")[0],
    // };
    let createData = await CreateData(data);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const editItemFunction = async (data, id) => {
    let data2 = {...data, qualification_id: data.qualification_id !== ""? data.qualification_id: undefined}
    let createData = await EditData(data2, id);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const editItemAdminFunction = async (data, id) => {
    let createData = await EditDataAdmin(data, id);
    if (createData.status === 200) {
      successToast(createData.msg);
    } else {
      errorToast(createData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
  };

  const deleteItem = async (element) => {
    let deletedData;
    if (Array.isArray(element)) {
      deletedData = await DeleteDataMassive(element);
    } else {
      deletedData = await DeleteData(element);
    }
    if (deletedData.status === 200) {
      successToast(deletedData.msg);
    } else {
      errorToast(deletedData.msg);
    }
    if (page === 1) {
      getInfo();
    } else {
      setPage(1);
    }
    return;
  };

  //////////////////////////// Render ////////////////////////////
  return (
    <>
      {/* <Helmet>
        <title> User | Minimal UI </title>
      </Helmet> */}

      <TableCustom
        dataList={dataList}
        dataHeader={TABLE_HEAD}
        titleCustom="Venta"
        titleCustomPlural={
          search !== ""
            ? `Ventas (filtrados por '${search}')`
            : "Ventas"
        }
        includeCreate={dataBasic.level_order == 0 ?false:true}
        includeCheck={dataBasic.level_order == 0 ? true:false}
        includeActions={true}
        includePhoto={false}
        page={page}
        totalData={totalData}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        deleteItem={deleteItem}
        setOpenForm={setOpenForm}
        editItem={editItem}
        handleFind={handleFind}
        order_by_default="name"
        type_order_by_default="asc"
      />
      {openForm && (
        <ModalAddEdit
          dataEdit={dataEdit}
          editItemFunction={editItemFunction}
          createItem={createItem}
          open={openForm}
          openEdit={openFormEdit}
          onClose={() => {
            setOpenForm(false);
            setOpenFormEdit(false);
          }}
          titleCustom={"Venta"}
          level_order={dataBasic.level_order}
          editItemAdminFunction={editItemAdminFunction}
        />
      )}
      <ToastContainer />
    </>
  );
}
