import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "", request_full = false) => {
  try {
    const result = await axiosApi().get(
      `/config/products-by-office/?page=${page}&limit=${limit}&search=${search}&request_full=${request_full}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Productos obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

// export const GetDataForm = async (is_edit = false) => {
//   try {
//     const result = await axiosApi().get(
//       `/config/products-by-office/form/?is_edit=${is_edit}`,
//     );
//     return {
//       status: 200,
//       data: result.data,
//       msg: "Productos obtenidos satisfactoriamente",
//     };
//   } catch (e) {
//     return {
//       status: 400,
//       msg: e?.response?.data?.detail
//         ? e.response.data.detail
//         : "Ocurrió un error, intente mas tarde",
//     };
//   }
// };

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/config/products-by-office/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Producto obtenido satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/config/products-by-office/${id}`);
    return { status: 200, data: result.data, msg: "Producto eliminado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/config/products-by-office/?productbyoffice_ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Productos eliminados" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/config/products-by-office", data);
    return { status: 200, data: result.data, msg: "Producto creado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/config/products-by-office/${id}`, data);
    return { status: 200, data: result.data, msg: "Producto actualizado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};
