import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "") => {
  try {
    const result = await axiosApi().get(
      `/catalog/levels-catalog/?page=${page}&limit=${limit}&search=${search}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Niveles obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataForm = async (is_edit = false, include_my_level = false) => {
  try {
    const result = await axiosApi().get(
      `/catalog/levels-catalog/form/?is_edit=${is_edit}&include_my_level=${include_my_level}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Niveles obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/catalog/levels-catalog/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Nivel obtenido satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/catalog/levels-catalog/${id}`);
    return { status: 200, data: result.data, msg: "Nivel eliminado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/catalog/levels-catalog/?level_catalog_ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Niveles eliminados" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/catalog/levels-catalog", data);
    return { status: 200, data: result.data, msg: "Nivel creado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/catalog/levels-catalog/${id}`, data);
    return { status: 200, data: result.data, msg: "Nivel actualizado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const VerifyLastLevel = async () => {
  try {
    const result = await axiosApi().get(
      `/catalog/levels-catalog/verify-last-record/`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Verificación obtenida",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};
