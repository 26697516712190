import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { GetData as GetDataProducts } from "../../axios/config/productbyoffice";
import { GetDataItemForm as GetDataQualifications } from "../../axios/catalog/qualification";
import { GetDataStatus } from "../../axios/sales";
import { GetDataForm as GetDataOffice } from "../../axios/config/workcenter";
import SalesVendorForm from "./sales/vendor";
import SalesDetailForm from "./sales/detailContact";
import SalesAdminForm from "./sales/admin";
import SalesInstaller from "./sales/installer"

const SalesModalForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  dataLevel = [],
  // dataOffice = []
  level_order,
  editItemAdminFunction
}) => {

  const user = useSelector((state) => state.user.user.dataBasic);

  ///////////// Tab general /////////////
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  // ------------------------------------- //

  ///////////// Active 0 /////////////

  const [dataTab0, setDataTab0] = useState({})

  const getDataOfficeAxios = async ()=>{
    let dataOfficesBack = await GetDataOffice()
    if (dataOfficesBack.status == 200){
      console.log("data: ", dataOfficesBack.data)
      setDataTab0({office_id: "", select_disabled: true, list_office: dataOfficesBack.data.data})
    }
  }

  // const getDataProductsAxios = async()=>{
  //   let products = await GetDataProducts(undefined,undefined,undefined,true)
  //   if (products.status == 200){
  //     setDataTab0({...dataTab0, products_list: products.data.data})
  //   }
  // }
  // ------------------------------------- //

  ///////////// Active 1 /////////////
  // ------------------------------------- //

  ///////////// Active 2 /////////////
  // ------------------------------------- //

  ///////////// Active 3 /////////////
  // ------------------------------------- //

  ///////////// Active 4 /////////////
  // ------------------------------------- //
  
  /////////////////////////////////////////

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label="Vendedor" />
          {/* <Tab label="Instalación" /> */}
          {user.level_order == 0 && <Tab label="Admin" />}
          {/* <Tab label="Administrador" /> */}
          {/* <Tab label="Instalación" /> */}
          {/* <Tab label="Pago" /> */}
          {/* Agrega más pestañas según sea necesario */}
        </Tabs>
      </DialogTitle>
      {/* Contenido de las pestañas */}
      {activeTab === 0 && <SalesVendorForm editItemFunction={editItemFunction} dataEdit={dataEdit} createItem={createItem} onClose={onClose} openEdit={openEdit} open={open}/>}
      {/* {activeTab === 1 && <SalesInstaller/>} */}
      {user.level_order == 0 && activeTab === 1 && <SalesAdminForm onClose={onClose} dataEdit={dataEdit} openEdit={openEdit} open={open} editItemFunction={editItemAdminFunction}/>}
      {/* {activeTab === 1 && <SalesAdminForm />} */}
      {/* {activeTab === 1 && <SalesInstaller />} */}
      {/* {activeTab === 4 && <div>Detalles de los pagos</div>} */}
      {/* Agrega más contenido según sea necesario */}
    </Dialog>
  );
};

export default SalesModalForm;
