import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { MenuItem } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import { GetData as GetDataProducts } from "../../../axios/config/productbyoffice";
import { GetDataItemForm as GetDataQualifications } from "../../../axios/catalog/qualification";
import { GetDataStatus } from "../../../axios/sales";

const SalesAdminForm = ({
  open,
  onClose,
  titleCustom = "",
  createItem,
  dataEdit,
  editItemFunction,
  openEdit,
  dataLevel = [],
  // dataOffice = []
  level_order
}) => {
  const [formData, setFormData] = useState({
    invoice: "",
    qualification_id: null,
    status_sale: ""
  });
  const [dataQualification, setDataQualification] = useState([])

  const user = useSelector((state) => state.user.user.dataBasic);

  useEffect(() => {
    if (openEdit) {
      dataFillEdit();
    }
  }, [openEdit]);

  const request_async_base = async()=>{
    let qualifications = await GetDataQualifications()
    if (qualifications.status == 200){
      setDataQualification(qualifications.data.data)
    }
  }

  const dataFillEdit = async() => {
    await request_async_base()
    setFormData({
      qualification_id: dataEdit.qualification_id ? dataEdit.qualification_id : "",
      status_sale: dataEdit.status_sale,
      invoice: dataEdit.invoice
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    let new_data ={...formData}
    if (new_data["status_sale"] == "1" && new_data["invoice"] !== ""){
      new_data["status_sale"] = "2"
    }
    if (new_data["invoice"] == ""){
      new_data["qualification_id"] = ""
    }
    if (new_data["qualification_id"] == ""){
      delete new_data["qualification_id"]
    }
    if (openEdit) {
      await editItemFunction(new_data, dataEdit.id);
    } else {
      await createItem(new_data);
    }
    onClose();
  };

  //////////////////////////////////////////
  
  /////////////////////////////////////////

  return(
    <Grid>
        {/* -------------------------- Apartado de información de venta --------------------------------*/}
        <Grid elevation={3} style={{ padding: 20 }} sx={{
          maxHeight: "80vh",
          position: "absolute",
          overflowY: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
          padding: "20px",
          "& > *": {
            // marginBottom: '16px', // Separación vertical uniforme entre elementos
            width: "100%", // Hacer que los elementos ocupen todo el ancho del modal
          },
        }}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              {/* <Typography variant="subtitle1" gutterBottom>
                Estatus de la venta
              </Typography>
              <Divider /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Folio de aprobación"
                name="invoice"
                value={formData.invoice}
                onChange={(item)=>handleChange(item)}
                disabled={user.level_order !== 0}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                label="Calificación"
                name="qualification_id"
                value={formData.qualification_id}
                onChange={(item)=>handleChange(item)}
                fullWidth
                select
                disabled ={user.level_order !== 0 && !(openEdit && formData.invoice != "")}
              >
                {dataQualification.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12} style={{textAlign:'center'}}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {openEdit ? "Actualizar" : "Guardar"}
        </Button>
        </Grid>
        <br />
    </Grid>
  )
};

export default SalesAdminForm;