import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "", request_full = false, is_revision = true) => {
  try {
    const result = await axiosApi().get(
      `/config/offices/?page=${page}&limit=${limit}&search=${search}&request_full=${request_full}&is_revision=${is_revision}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Oficinas obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataForm = async () => {
  try {
    const result = await axiosApi().get(
      `/config/offices/form/full`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Oficinas obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/config/offices/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Oficina obtenida satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/config/offices/${id}`);
    return { status: 200, data: result.data, msg: "Oficina eliminada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/config/offices/?office_ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Oficinas eliminadas" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/config/offices", data);
    return { status: 200, data: result.data, msg: "Oficina creada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/config/offices/${id}`, data);
    return { status: 200, data: result.data, msg: "Oficina actualizada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataFormOwnerProfile = async (id) => {
  try {
    const result = await axiosApi().get(
      `/config/offices/form/full/${id}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Oficinas obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};