export const createFiltersJB = ({
    obj = {},
    discard = []
}) => {
    let noValid = [undefined, null, "", " "];
    return Object.entries(obj).reduce((filters, [key, val]) => {
        if (discard.includes(key)) return filters
        let value = val?.toString().trim();
        if (noValid.includes(value)) return filters;
        return { ...filters, [key]: value }
    }, {});
}

export const getFiltersJB = ({
    obj = {},
    discard = [],
    isFirst = false
}) => {
    if (Object.keys(obj).length <= 0) return '';

    const getQuery = (param, query, idx) => {
        return isFirst && (idx == 0 || query == '')
            ? `?${query}${param}`
            : `${query}&${param}`;
    }

    return Object.entries(obj).reduce((query, [key, val], idx) => {
        if (["size", ...discard].includes(key)) return query;
        if (key == "page") {
            const find_ = item => item[0] == "size";
            let result = Object.entries(obj).find(find_);
            let limit = result ? parseInt(result[1]) : 10;
            let offset = (parseInt(val) - 1) * limit;
            let param = `limit=${limit}&offset=${offset}`;
            return getQuery(param, query, idx);
        }
        return getQuery(`${key}=${val}`, query, idx);
    }, '');
}

export const deleteFiltersJb = ({
    obj = {},
    listDelete = []
}) => {
    return Object.entries(obj).reduce((filters, [key, val]) => {
        if (listDelete.includes(key)) return filters;
        return { ...filters, [key]: val };
    }, {});
}

export const getValueFilter = ({
    value = '',
    list = [],
    keyEquals = 'id',
    keyShow = 'name'
}) => {
    let novalid = [undefined, null, "", ""];
    if (novalid.includes(value)) return value;
    const find_ = item => item[keyEquals] == value;
    let result = list.find(find_);
    if (novalid.includes(result)) return value;
    return typeof keyShow == 'function'
        ? keyShow(result) : result[keyShow];
}