import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, List, ListItemText, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import { StyledNavItem, StyledNavItemIcon } from "./styles";

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, subMenu } = item;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleClick = () => {
    if (subMenu) {
      setOpenSubMenu(!openSubMenu);
    }
  };

  return (
    <div>
      <StyledNavItem
        component={subMenu ? "div" : RouterLink}
        to={subMenu ? null : path}
        sx={{
          "&.active": {
            color: "text.primary",
            bgcolor: "action.selected",
            fontWeight: "fontWeightBold",
          },
        }}
        onClick={handleClick}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {subMenu && (
          <IconButton size="small" sx={{ marginLeft: "auto" }}>
            {openSubMenu ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
        {info && info}
      </StyledNavItem>
      {subMenu && (
        <Collapse in={openSubMenu}>
          <List style={{ marginLeft: 20 }} disablePadding>
            {subMenu.map((subItem) => (
              <NavItem key={subItem.title} item={subItem} />
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
}
