import axiosApi from "../axiosApi";

export const GetData = async (page = 1, limit = 5, search = "") => {
  try {
    const result = await axiosApi().get(
      `/catalog/state/?page=${page}&limit=${limit}&search=${search}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Estados obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataForm = async () => {
  try {
    const result = await axiosApi().get(
      `/catalog/state/form/full`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Estados obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`/catalog/state/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Estado obtenido satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`/catalog/state/${id}`);
    return { status: 200, data: result.data, msg: "Estado eliminado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `/catalog/state/?ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Estados eliminados" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("/catalog/state", data);
    return { status: 200, data: result.data, msg: "Estado creado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`/catalog/state/${id}`, data);
    return { status: 200, data: result.data, msg: "Estado actualizado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};
