import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import installerSlice from "./installerSlice";

export const store = configureStore({
  reducer: {
    installerStore: installerSlice,
    user: userReducer,
  },
});
