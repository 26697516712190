import axios from "axios";

export const domainApi = process.env.REACT_APP_URL_API;

export const typeHttp = process.env.REACT_APP_TYPE_HTTP;

export const urlAPI = `${typeHttp}://${domainApi}/`;

const axiosApi = (contentType = "application/json") => {
  const axiosApi2 = axios.create({
    baseURL: urlAPI,
    headers: { "Content-Type": contentType },
  });
  axiosApi2.defaults.timeout = 0;

  axiosApi2.interceptors.request.use(
    async function (config) {
      let token = localStorage.getItem("access_token")
        ? JSON.parse(localStorage.getItem("access_token"))
        : null;
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  // axiosApi2.interceptors.response.use((response) => {
  //   return response;
  // }, (error) => {
  //   return Promise.reject(error.message);
  // });
  return axiosApi2;
};
export default axiosApi;
