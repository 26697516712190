import { CloseOutlined } from '@mui/icons-material';
import { Box, ButtonBase, Drawer, Stack, Typography } from '@mui/material'
import React from 'react';
import CustomLoading from './CustomLoading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    rowGap: 1,
    p: 2,
};

const CustomDrawer = ({
    children,
    visible = false,
    onClose = () => { },
    title = '',
    width = 500,
    closable = true,
    loading = false
}) => {
    return (
        <Drawer
            open={visible}
            anchor='right'
            disableEscapeKeyDown
            slotProps={{
                backdrop: { sx: { background: 'rgba(0,0,0,0.4)' } }
            }}
        >
            {loading && <CustomLoading />}
            <Box
                flex={1}
                display='flex'
                flexDirection='column'
                sx={{ width, rowGap: 1, padding: 2 }}
            >
                {(title || closable) && (
                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                        <Typography variant='subtitle1'>
                            {title}
                        </Typography>
                        {closable && (
                            <ButtonBase onClick={() => onClose()}>
                                <CloseOutlined />
                            </ButtonBase>
                        )}
                    </Stack>
                )}
                {children}
            </Box>
        </Drawer>
    )
}

export default CustomDrawer