import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    list_assignments: {},
    load_assignments: false,
    installer_page: 1,
    installer_filters: "",
    installer_page_size: 10,
}

const types = {
    GET_ASSIGNMENTS: "GET_ASSIGNMENTS"
}

const commonAction = (state, action) => ({
    ...state, ...action.payload
})

const reducers = {
    [types.GET_ASSIGNMENTS]: commonAction
}

const installerSlice = createSlice({
    name: 'installer',
    initialState,
    reducers
})

export const actions = installerSlice.actions;

const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};

export const getAssignments = (query = '', page = 1, size = 10) => (dispatch) => {
    try {
        dispatch(actions.GET_ASSIGNMENTS({ load_assignments: true }))
        let results = Array(50).fill(null).map((_, idx) => {
            let status = Math.floor(Math.random() * 3) + 1;
            let extra = Array(10).fill(null).map((_, index) => ({
                concept: `Concepto ${index + 1}  Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
                amount: Math.random() * 1000
            }))
            return {
                id: uniqueId(),
                seller: `Vendedor ${idx + 1}`,
                status: status,
                extra: extra,
                install: `Equipo a instalar ${idx + 1}`,
                detail: {
                    address: `Dirrección ${idx + 1} Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat`,
                    comment: `Comentario ${idx + 1} Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat`
                }

            }
        })
        setTimeout(() => {
            dispatch(actions.GET_ASSIGNMENTS({
                load_assignments: false,
                installer_page: page,
                installer_filters: query,
                installer_page_size: size,
                list_assignments: {
                    page, count: 50,
                    results
                }
            }))
        }, 2000)
    } catch (e) {
        console.log(e)
        dispatch(actions.GET_ASSIGNMENTS({ load_assignments: false }))
    }
}

export default installerSlice.reducer;