import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useSelector, useDispatch } from "react-redux";
import { errorToast, successToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GetDataUser, DeleteAccount } from "../axios/auth";
import { EditData } from "../axios/recommended";
import { updateDataUser, logoutSuccess } from "../redux/userSlice";
import ChangePasswordForm from "../components/ChangePasswordForm";
import {
  GetDataForm as GetStatesApi
} from "../axios/catalog/state";
import {
  GetDataForm as GetCitiesApi
} from "../axios/catalog/city";
import { formatPhoneNumber } from "../utils/phoneNumber";
// ----------------------------------------------------------------------

export default function ProfileForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataBasic = useSelector((state) => state.user.user.dataBasic);
  const user_id = useSelector((state) => state.user.userId);
  const accessToken = useSelector((state) => state.user.accessToken);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openChangePassDialog, setOpenChangePassDialog] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    birthday: null,
    address_1: "",
    address_2: "",
    city_id: "",
    state_id: "",
    zip_code: "",
    level_id: "",
  });
  const [dataStates, setDataStates] = useState([])
  const [dataCities, setDataCities] = useState([])

  useEffect(() => {
    getInfo();
  }, []);

  //////////////////////////// Consumo de backend ////////////////////////////
  const getInfo = async () => {
    let dataUser = await GetDataUser(user_id);
    if (dataUser.status === 200) {
      let dataProfile = dataUser.data.profile;
      let dateObject = null;
      if (dataProfile.birthday) {
        const [year, month, day] = dataProfile.birthday.split("-");
        dateObject = new Date(year, month - 1, day);
      }
      await getStates()
      if (dataProfile.city_id){
        await getCities(dataProfile.state_id)
      }
      setFormData({
        first_name: dataProfile.first_name,
        last_name: dataProfile.last_name,
        email: dataUser.data.email,
        phone: dataProfile.phone,
        birthday: dateObject,
        address_1: dataProfile.address_1,
        address_2: dataProfile.address_2,
        city_id: dataProfile.city_id,
        state_id: dataProfile.state_id,
        zip_code: dataProfile.zip_code,
      });
      setValue("first_name", dataProfile.first_name);
      setValue("last_name", dataProfile.last_name);
      setValue("email", dataUser.data.email);
      setValue("phone", dataProfile.phone);
      setValue("birthday", dateObject);
      setValue("address_1", dataProfile.address_1);
      setValue("address_2", dataProfile.address_2);
      setValue("city_id", dataProfile.city_id);
      setValue("state_id", dataProfile.state_id);
      setValue("zip_code", dataProfile.zip_code);
      // let newListLevel = infoLevels.data.data.filter(item=>(item.order>user.level_order))
      // console.log(newListLevel)
      // setListLevel(newListLevel)
    }
  };

  const getStates = async () =>{
    let states_list = await GetStatesApi()
    if (states_list.status == 200){
      setDataStates(states_list.data.data)
    }
  }

  const getCities = async (id) =>{
    let cities_list = await GetCitiesApi(id)
    if (cities_list.status == 200){
      setDataCities(cities_list.data.data)
    }
  }

  const handleClick = async (value) => {
    delete value.email;
    if (value.birthday) {
      value.birthday = value.birthday.toISOString().split("T")[0];
    }
    const response = await EditData(value, dataBasic.profile_id);
    if (response.status === 200) {
      dispatch(
        updateDataUser({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          photo_url: response.data.photo_url,
        }),
      );
      successToast("Perfil actualizado exitosamente");
    } else {
      errorToast(response.msg);
    }
  };

  const handleDeleteClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleChangePassClick = () => {
    setOpenChangePassDialog(true);
  };

  const handleChangePassClose = () => {
    setOpenChangePassDialog(false);
  };

  const handlerDeleted = async () => {
    // await deleteItem(selected.length > 0 ? selected : currentIdAction);
    let response = await DeleteAccount({email: formData.email})
    if (response.status == 200){
      dispatch(logoutSuccess());
      setOpenConfirmationDialog(false);
      successToast(response.msg);
      navigate("/security/login", { replace: true });
    }else{
      errorToast(response.msg)
    }
    
  };

  const handleDateChange = (field, newDate) => {
    setFormData({
      ...formData,
      [field]: newDate,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleClick)}>
        <Stack spacing={3}>
          <Controller
            name="first_name"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.first_name}
                label="Nombre"
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("first_name", e.target.value);
                  setFormData({
                    ...formData,
                    first_name: e.target.value,
                  });
                }}
              />
            )}
          />

          <Controller
            name="last_name"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.last_name}
                label="Apellidos"
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("last_name", e.target.value);
                  setFormData({
                    ...formData,
                    last_name: e.target.value,
                  });
                }}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            disabled={true}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.email}
                label="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("email", e.target.value);
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.phone}
                label="Teléfono"
                error={!!errors.phone}
                helperText={errors.phone?.message}
                onChange={(e) => {
                  const formattedValue = formatPhoneNumber(e.target.value);
                  field.onChange(e);
                  setValue("phone", formattedValue);
                  setFormData({
                    ...formData,
                    phone: formattedValue,
                  });
                }}
              />
            )}
          />

          <Controller
            name="birthday"
            control={control}
            // rules={{
            //   required: 'Este campo es requerido',
            // }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  fullWidth
                  name="birthday"
                  value={formData.birthday}
                  onChange={(newDate) => {
                    handleDateChange("birthday", newDate);
                    field.onChange(newDate);
                    setValue("birthday", newDate);
                  }}
                  // error={!!errors.birthday}
                  // helperText={errors.birthday?.message}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Cumpleaños"
                      // error={!!errors.birthday}
                      // helperText={errors.birthday?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />

          <Controller
            name="address_1"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.address_1}
                label="Dirección 1"
                error={!!errors.address_1}
                helperText={errors.address_1?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("address_1", e.target.value);
                  setFormData({
                    ...formData,
                    address_1: e.target.value,
                  });
                }}
              />
            )}
          />

          <Controller
            name="address_2"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Dirección 2"
                value={formData.address_2}
                error={!!errors.address_2}
                helperText={errors.address_2?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("address_2", e.target.value);
                  setFormData({
                    ...formData,
                    address_2: e.target.value,
                  });
                }}
              />
            )}
          />

          <Controller
            name="state_id"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.state_id}
                label="Estado"
                error={!!errors.state_id}
                helperText={errors.state_id?.message}
                select
                onChange={(e) => {
                  field.onChange(e);
                  setValue("state_id", e.target.value);
                  setValue("city_id", null);
                  setFormData({
                    ...formData,
                    state_id: e.target.value,
                  });
                  getCities(e.target.value)
                }}
              >
                {dataStates.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </TextField>
            )}
          />

          <Controller
            name="city_id"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.city_id}
                label="Ciudad"
                error={!!errors.city_id}
                helperText={errors.city_id?.message}
                select
                onChange={(e) => {
                  field.onChange(e);
                  setValue("city_id", e.target.value);
                  setFormData({
                    ...formData,
                    city_id: e.target.value,
                  });
                }}
                >
                {dataCities.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </TextField>
            )}
          />

          <Controller
            name="zip_code"
            control={control}
            rules={{
              required: "Este campo es requerido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={formData.zip_code}
                label="Código postal"
                error={!!errors.zip_code}
                helperText={errors.zip_code?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setValue("zip_code", e.target.value);
                  setFormData({
                    ...formData,
                    zip_code: e.target.value,
                  });
                }}
              />
            )}
          />

          <LoadingButton
            style={{ marginTop: 25 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!Object.keys(errors).length === 0}
          >
            Actualizar datos
          </LoadingButton>
          <Button variant="outlined" color="primary" size="large" style={{borderColor:"orange", color: "orange"}} onClick={()=>handleChangePassClick()}>
            Cambiar contraseña
          </Button>
          <Button variant="outlined" color="primary" size="large" style={{borderColor:"red", color: "red"}} onClick={()=>handleDeleteClick()}>
            Eliminar cuenta
          </Button>
        </Stack>
      </form>
      {openConfirmationDialog && (
        <Dialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
        >
          <DialogTitle>Confirmar eliminación de cuenta</DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Está seguro de eliminar su cuenta?, esta acción es permanente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenConfirmationDialog(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setOpenConfirmationDialog(false);
                handlerDeleted();
              }}
              color="error"
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openChangePassDialog && (
        <Dialog
          open={openChangePassDialog}
          onClose={() => setOpenChangePassDialog(false)}
        >
          <DialogContent>
            <ChangePasswordForm token={accessToken} legendButton={"Cambiar contraseña"} redirect={false} afterAction={()=>handleChangePassClose()}/>
          </DialogContent>
        </Dialog>
      )}
      <ToastContainer />
    </>
  );
}
