import React, {
    useEffect,
    useMemo, useState
} from 'react';
import CustomModal from '../CustomModal';
import CustomDrawer from '../CustomDrawer';
import { Button, TextField, Stack, Typography, ButtonBase } from '@mui/material';
import SimpleBar from 'simplebar-react';
import { useForm, Controller } from 'react-hook-form';
import { CloseOutlined, DeleteOutline } from '@mui/icons-material';
import { successToast, errorToast } from '../../utils/toast';

const DrawerExtra = ({
    visible = true,
    onClose = () => { },
    itemDetail = {},
    onReady = () => { }
}) => {

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const [newExtra, setNewExtra] = useState([]);
    const [prevExtra, setPrevExtra] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            let list = itemDetail?.extra || [];
            setPrevExtra(list)
        }
    }, [itemDetail?.extra, visible])

    const actionForm = async () => {
        try {
            setLoading(true)
            // let response = await 
            setTimeout(() => {
                setLoading(false)
                onReady()
                handleClose()
                successToast('Gastos registrados')
            }, 2000)
        } catch (e) {
            console.log()
            setLoading(false)
            errorToast('Gastos no registrados')
        }
    }

    const handleClose = () => {
        onClose()
        reset()
        setNewExtra([])
        setPrevExtra([])
    }

    const onFinish = (values) => {
        values.amount = parseFloat(values.amount);
        setNewExtra(prev => ([...prev, values]));
        reset()
    }

    const deleteItem = (index, isPrev) => {
        let list = [...isPrev ? prevExtra : newExtra];
        list.splice(index, 1);
        if (isPrev) setPrevExtra(list);
        else setNewExtra(list)
    }

    const FieldExtra = ({ record, index, isPrev }) => {
        return (
            <Stack
                display='flex'
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                    px: 1, py: 1,
                    ':not(:last-child)': {
                        borderBottom: '1px solid #dddd'
                    }
                }}
            >
                <p style={{
                    margin: 0,
                    lineHeight: 1.2,
                    flex: itemDetail?.status == 3 ? '1 0 80%' : '1 0 70%',
                    paddingRight: 4
                }}>
                    {record?.concept}
                </p>
                <p style={{ margin: 0, lineHeight: 1.2, flex: '1 0 20%', paddingLeft: 4 }}>
                    ${record?.amount?.toFixed(2)}
                </p>
                {itemDetail?.status == 2 && (
                    <ButtonBase onClick={() => deleteItem(index, isPrev)} sx={{ flex: '1 0 10%' }}>
                        <CloseOutlined fontSize='small' />
                    </ButtonBase>
                )}
            </Stack>
        )
    }

    return (
        <CustomDrawer
            visible={visible}
            onClose={handleClose}
            loading={loading}
            title='Gastos extraordinarios'
        >
            {itemDetail?.status == 2 && (
                <form onSubmit={handleSubmit(onFinish)} style={{ display: 'flex', columnGap: 8, alignItems: 'baseline' }}>
                    <Controller
                        name='concept'
                        control={control}
                        defaultValue=''
                        rules={{ required: 'Concepto requerido' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size='small'
                                label='Concepto'
                                error={!!errors.concept}
                                helperText={errors?.concept?.message}
                            />
                        )}
                    />
                    <Controller
                        name='amount'
                        control={control}
                        defaultValue=''
                        rules={{
                            required: 'Monto requerido',
                            min: {
                                value: 1,
                                message: 'Monto no válido'
                            }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size='small'
                                label='Monto'
                                type='number'
                                error={!!errors.amount}
                                helperText={errors?.amount?.message}
                            />
                        )}
                    />
                    <Button type='submit' variant='contained'>
                        Agregar
                    </Button>
                </form>
            )}
            <SimpleBar style={{
                maxHeight: window.innerHeight - (itemDetail?.status == 3 ? 80 : 156),
                border: '1px solid #dddd',
                borderRadius: 8,
                'MuiStack-root:not(:last-child)': {
                    background: 'red'
                }
            }}>
                {(prevExtra?.length > 0 || newExtra?.length > 0) ? (
                    <>
                        {prevExtra?.map((record, idx) => (
                            <FieldExtra key={idx} index={idx} isPrev={true} record={record} />
                        ))}
                        {newExtra?.map((record, idx) => (
                            <FieldExtra key={idx} index={idx} isPrev={false} record={record} />
                        ))}
                    </>
                ) : (
                    <Typography align='center' marginTop={4} marginBottom={4} color='#dedfe0'>
                        Ningún resultado encontrado
                    </Typography>
                )}
            </SimpleBar>
            {itemDetail?.status == 2 && (
                <Stack
                    direction='row'
                    spacing={1}
                    justifyContent='flex-end'
                    sx={{ marginTop: 'auto' }}
                >
                    <Button variant='outlined' onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        variant='contained'
                        // disabled={!(prevExtra?.length < itemDetail?.extra?.length) || !(newExtra?.length > 0)}
                        onClick={() => actionForm()}
                    >
                        Guardar
                    </Button>
                </Stack>
            )}
        </CustomDrawer>
    )
}

export default DrawerExtra