// TableRow.js
import React from 'react';
import { TableRow, TableCell, TextField } from '@mui/material';

const TableRowComponent = ({ row, headers, onCellValueChange }) => {
  return (
    <TableRow>
      <TableCell>{row.name}</TableCell>
      {headers.map((header) => (
        <TableCell key={header.id}>
          <TextField
            type="number"
            onChange={(e) => onCellValueChange(row.id, header.id, e.target.value)}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableRowComponent;
