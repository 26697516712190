import axiosApi from "./axiosApi";

export const GetData = async (page = 1, limit = 5, search = "", userId) => {
  try {
    const result = await axiosApi().get(
      `auth/user/recommended/?page=${page}&limit=${limit}&user_id=${userId}&search=${search}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Asociados obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`auth/user/recommended/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Asociado obtenido satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`auth/user/recommended/${id}`);
    return { status: 200, data: result.data, msg: "Asociado eliminado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `auth/user/recommended/?ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Instaladores eliminados" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("auth/user/recommended", data);
    return { status: 200, data: result.data, msg: "Asociado creado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`auth/user/recommended/${id}`, data);
    return { status: 200, data: result.data, msg: "Asociado actualizado" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

// export const UpdateUrlPhoto = async (profileId) => {
//     try{
//         const result = await axiosApi().get(`auth/user/update-url-image/${profileId}`);
//         return {status: 200, data: result.data, msg: 'Imagen actualizada correctamente'}
//     }catch(e){
//         console.log(e)
//         return {status:400, msg: e?.response?.data?.detail ? e.response.data.detail: 'Ocurrió un error, intente mas tarde'}
//     }
// };

export const uploadPhoto = async (data) => {
  try {
    const result = await axiosApi("multipart/form-data").post(
      "auth/user/upload-photo",
      data,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Foto actualizada con éxito",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataOrder = async (order=1) => {
  try {
    const result = await axiosApi().get(
      `auth/user/recommended/form/order/full/?order=${order}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Asociados obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};