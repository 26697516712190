import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ProfileForm from "../components/ProfileForm";
import ProfilePictureUploader from "../components/ProfilePictureUploader";
import { useSelector, useDispatch } from "react-redux";
import { uploadPhoto } from "../axios/recommended";
import { setUrlPhoto } from "../redux/userSlice";
import { ToastContainer } from "react-toastify";

export default function ProfilePage() {
  const user = useSelector((state) => state.user.user.dataBasic);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 5),
  }));

  const handleImageUpload = async (newImage) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("photo", newImage);

    let response = await uploadPhoto(formData);

    if (response.status == 200) {
      dispatch(setUrlPhoto(response.data.url));
    } else {
      alert("No se subió la foto");
    }
    setIsUploading(false);
  };

  return (
    <StyledContent>
      {/* <Typography variant="h4" gutterBottom>
        Sign in to Minimal
      </Typography> */}

      <div>
        <ProfilePictureUploader
          isUploading={isUploading}
          imageUrl={
            user?.photo_url ? user.photo_url : "/assets/Default_person.png"
          }
          onImageUpload={handleImageUpload}
        />
      </div>

      <ProfileForm />
      <ToastContainer />
    </StyledContent>
  );
}
