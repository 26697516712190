import React, { useState } from 'react';
import CustomModal from '../CustomModal';
import { useForm, Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, TextField, MenuItem, Stack, Button } from '@mui/material';
import { optionsStatus } from '../../utils/constants';
import { createFiltersJB } from '../../utils/functions';

const ModalFilters = ({
    visible = false,
    onClose = () => { },
    actionForm = () => { }
}) => {

    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const onFinish = (values) => {
        let filters = createFiltersJB({ obj: values });
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            actionForm(filters)
            handleClose()
        }, 2000)
    }

    const handleClose = () => {
        onClose()
        reset()
    }

    return (
        <CustomModal
            visible={visible}
            width={400}
            title='Configurar filtros'
            loading={loading}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit(onFinish)}>
                <Stack spacing={2}>
                    <Controller
                        name='install'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size='small'
                                fullWidth
                                label='Equipo'
                            />
                        )}
                    />
                    <FormControl size='small'>
                        <InputLabel id='select-status'></InputLabel>
                        <Controller
                            name='status'
                            control={control}
                            defaultValue={1}
                            render={({ field }) => (
                                <Select labelId='select-status' {...field}>
                                    {optionsStatus?.length > 0 && optionsStatus.map((item, idx) => (
                                        <MenuItem value={item?.value} key={idx}>
                                            {item?.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <Stack direction='row' justifyContent='flex-end' spacing={1}>
                        <Button variant='outlined' onClick={() => handleClose()}>
                            Cancelar
                        </Button>
                        <Button variant='contained' type='submit'>
                            Aplicar
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </CustomModal>
    )
}

export default ModalFilters