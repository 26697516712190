import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
import LoginPage from "./pages/security/Login";
import Page404 from "./pages/Page404";
import HomePage from "./pages/Home";
import FinancingRequest from "./pages/financing-request";
import Recommended from "./pages/RecommendedPage";
import Sales from "./pages/SalesPage";
import Installers from "./pages/config/InstallersPage";
import Workcenter from "./pages/config/WorkcentersPage";
import RequestChangePassword from "./pages/security/RequestChangePassword";
import LevelCatalog from "./pages/catalog/LevelCatalogPage";
import QualificationCatalog from "./pages/catalog/QualificationCatalogPage";
import ProductCatalog from "./pages/catalog/ProductCatalogPage";
import ChangePassword from "./pages/security/ChangePassword";
import ProfilePage from "./pages/ProfilePage";
import ProductsByOfficePage from "./pages/config/ProductByOfficePage";
import StateCatalogPage from "./pages/catalog/StateCatalogPage";
import CityCatalogPage from "./pages/catalog/CityCatalogPage";
//Installer
import LayoutInstaller from "./pages/installer/layout";
import AssignPage from "./pages/installer/assign";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/financing-request" />, index: true },
        { path: "home", element: <HomePage /> },
        { path: "financing-request", element: <FinancingRequest /> },
        { path: "associates", element: <Recommended /> },
        { path: "sales", element: <Sales /> },
        { path: "profile", element: <ProfilePage /> },
      ],
    },
    {
      path: "/settings",
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/home" />, index: true },
        { path: "installers", element: <Installers /> },
        { path: "workcenter", element: <Workcenter /> },
        { path: "products", element: <ProductsByOfficePage /> }
      ],
    },
    {
      path: "/catalog",
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/home" />, index: true },
        { path: "levels", element: <LevelCatalog /> },
        { path: "qualifications", element: <QualificationCatalog /> },
        { path: "products", element: <ProductCatalog /> },
        { path: "state", element: <StateCatalogPage /> },
        { path: "city", element: <CityCatalogPage /> }
      ],
    },
    {
      path: '/security',
      children: [
        { path: 'login', element: <LoginPage isAdmin={true} /> },
        { path: 'request-change-password', element: <RequestChangePassword /> },
        { path: 'recovery-password', element: <ChangePassword /> },
        { path: 'installer/login', element: <LoginPage isAdmin={false} /> }
      ]
    },
    {
      path: '/installer',
      element: <LayoutInstaller />,
      children: [
        { path: 'assign', element: <AssignPage /> }
      ]
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
