export const optionsStatus = [
    { value: 1, label: 'Pendiente' },
    { value: 2, label: 'En curso' },
    { value: 3, label: 'Finalizado' }
]

export const statusSaleList = [
    {id: "1", name: "En revisión por administrador"},
    {id: "2", name: "En espera de asignación de instalador"},
    {id: "3", name: "Instalador asignado, en espera de atención"},
    {id: "4", name: "Instalación en proceso"},
    {id: "5", name: "Instalación finalizada"},
    {id: "6", name: "Orden cerrada"}
  ]