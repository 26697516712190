import axiosApi from "./axiosApi";

export const GetData = async (page = 1, limit = 5, search = "", userId) => {
  try {
    const result = await axiosApi().get(
      `general/sales/?page=${page}&limit=${limit}&user_id=${userId}&search=${search}`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Ventas obtenidas satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const GetDataItem = async (id) => {
  try {
    const result = await axiosApi().get(`general/sales/${id}`);
    return {
      status: 200,
      data: result.data,
      msg: "Venta obtenida satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteData = async (id) => {
  try {
    const result = await axiosApi().delete(`general/sales/${id}`);
    return { status: 200, data: result.data, msg: "Venta eliminada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const DeleteDataMassive = async (listItem) => {
  try {
    const result = await axiosApi().delete(
      `general/sales/?ids=${listItem.join(",")}`,
    );
    return { status: 200, data: result.data, msg: "Venta eliminada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const CreateData = async (data) => {
  try {
    const result = await axiosApi().post("general/sales", data);
    return { status: 200, data: result.data, msg: "Venta creada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditData = async (data, id) => {
  try {
    const result = await axiosApi().put(`general/sales/${id}`, data);
    return { status: 200, data: result.data, msg: "Venta actualizada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};

export const EditDataAdmin = async (data, id) => {
  try {
    const result = await axiosApi().put(`general/sales/${id}/admin`, data);
    return { status: 200, data: result.data, msg: "Venta actualizada" };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};



export const GetDataStatus = async () => {
  try {
    const result = await axiosApi().get(
      `general/sales/status/list`,
    );
    return {
      status: 200,
      data: result.data,
      msg: "Estatus obtenidos satisfactoriamente",
    };
  } catch (e) {
    return {
      status: 400,
      msg: e?.response?.data?.detail
        ? e.response.data.detail
        : "Ocurrió un error, intente mas tarde",
    };
  }
};