import React from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import LoginForm from "../../components/LoginForm";

function Login({ isAdmin = true }) {

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 5),
  }));

  return (
    <StyledContent>
      {/* <Typography variant="h4" gutterBottom>
        Sign in to Minimal
      </Typography> */}

      <img
        style={{ alignSelf: "center", height: "100px" }}
        alt={""}
        src="/assets/logo.png"
      />

      <Typography style={{ alignSelf: "center" }} variant="h4" sx={{ mb: 5 }}>
        ¡Hola, bienvenido!
      </Typography>

      <LoginForm isAdmin={isAdmin}/>

      {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          O puedes intentar con
        </Typography>
      </Divider>

      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
        </Button>

        <Button fullWidth size="large" color="inherit" variant="outlined">
          <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
        </Button>

      </Stack> */}
    </StyledContent>
  );
}

export default Login;
