import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ChangePasswordForm from "../../components/ChangePasswordForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import { VerifyTokenRequestChangePassword } from "../../axios/auth";
import { errorToast } from "../../utils/toast";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [tokenVerify, setTokenVerify] = useState(null);

  useEffect(() => {
    verifyToken();
  }, [queryParams]);

  const verifyToken = async () => {
    const token = queryParams.get("token");
    if (token) {
      let verify = await VerifyTokenRequestChangePassword({ token: token });
      if (verify.status === 200) {
        setTokenVerify(token);
        setIsLoading(false);
      } else {
        errorToast("Link inválido");
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 4000);
      }
    } else {
      errorToast("Petición inválida");
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 4000);
    }
  };

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0, 5),
  }));

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Centrar horizontalmente
            alignItems: "center", // Centrar verticalmente
            height: "100vh", // Establecer la altura al 100% del viewport
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <StyledContent>
          <img
            style={{ alignSelf: "center", height: "100px" }}
            alt={""}
            src="/assets/logo.png"
          />

          <Typography
            style={{ alignSelf: "center" }}
            variant="h4"
            sx={{ mb: 5 }}
          >
            ¡Hola, bienvenido!
          </Typography>

          <ChangePasswordForm token={tokenVerify} legendButton={"Reestablecer contraseña"}/>
        </StyledContent>
      )}
      <ToastContainer />
    </>
  );
}

export default ChangePassword;
