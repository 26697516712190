import React from "react";
import { Box, Paper } from "@mui/material";

function IframeView() {
  return (
    <Box sx={{ height: "80vh", display: "flex", flexDirection: "column" }}>
      <Paper elevation={3} sx={{ flex: 1, padding: 2 }}>
        <iframe
          src="https://form.jotform.com/221377556696167"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          title="Mi iFrame"
        />
      </Paper>
    </Box>
  );
}

export default IframeView;
