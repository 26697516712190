import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { ChangePassword } from "../axios/auth";
import { successToast, errorToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";
import Iconify from "../utils/iconify";

// ----------------------------------------------------------------------

export default function RecoverForm({ token = null, legendButton="", redirect = true, afterAction=()=>console.log("")}) {
  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClick = async (value) => {
    let newValue = { password: value.password, token: token };
    const response = await ChangePassword(newValue);
    if (response.status === 200) {
      successToast(response.msg);
      afterAction()
      if (redirect){
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 4000);
      }
    } else {
      errorToast(response.msg);
    }
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Typography
          style={{ marginBottom: 0 }}
          variant="subtitle2"
          sx={{ mb: 5 }}
        >
          Ingresa y confirma tu nueva contraseña.
        </Typography>
      </Stack>
      <form onSubmit={handleSubmit(handleClick)}>
        <Stack spacing={3}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: "Contraseña es requerida",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Contraseña"
                type={showPassword1 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword1(!showPassword1)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword1 ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: "Confirmación de contraseña es requerida",
              validate: (value) =>
                value === watch("password") || "Las contraseñas no coinciden",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirmar Contraseña"
                type={showPassword2 ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword2(!showPassword2)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword2 ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            )}
          />

          <LoadingButton
            style={{ marginTop: 25 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!Object.keys(errors).length === 0}
          >
            {legendButton}
          </LoadingButton>
        </Stack>
      </form>
      {/* </Stack> */}
      <ToastContainer />
    </>
  );
}
