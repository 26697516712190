import React, { useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function ProfilePictureUploader({ imageUrl, onImageUpload, isUploading }) {
  const roundedImageStyle = {
    width: "200px",
    height: "200px",
    borderRadius: "50%",
    cursor: "pointer",
  };

  const inputRef = useRef(null);

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    onImageUpload(selectedFile);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "50px",
      }}
    >
      {!isUploading && (
        <img
          src={imageUrl}
          alt="Profile Picture"
          onClick={handleImageClick}
          style={roundedImageStyle}
        />
      )}
      {isUploading && (
        <CircularProgress
          size={100}
          style={{
            zIndex: 1,
            borderRadius: "50%",
          }}
        />
      )}
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
}

export default ProfilePictureUploader;
