import Chart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

export { default as StyledChart } from "./styles";

export { default as useChart } from "./useChart";

export default Chart;
