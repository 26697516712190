import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const CustomLoading = ({
    style = {}
}) => {
    return (
        <Box
            position='absolute'
            display='flex'
            justifyContent='center'
            alignItems='center'
            zIndex={1}
            sx={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.7)',
                ...style
            }}
        >
            <CircularProgress size={30} />
        </Box>
    )
}

export default CustomLoading