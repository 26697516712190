import React, { useState } from 'react';
import TableHeader from './TableHeader';
import TableRowComponent from './TableRow';

const dataHeaders = [{ id: 1, name: 'A5' }, { id: 2, name: 'B1' }, { id: 3, name: 'D4' }];
const dataRows = [{ id: 1, name: 'Director' }, { id: 2, name: 'Subdirector' }, { id: 3, name: 'Regional' }];

const Home = () => {
  const [tableData, setTableData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [price, setPrice] = useState(0);
  const [isDefault, setIsDefault] = useState(false);

  const handleCellValueChange = (rowId, headerId, value) => {
    const updatedTableData = { ...tableData };

    if (!updatedTableData[rowId]) {
      updatedTableData[rowId] = {};
    }

    updatedTableData[rowId][headerId] = parseFloat(value) || 0;

    setTableData(updatedTableData);
  };

  return (
    <div>
      <div>
        <label htmlFor="product">Product:</label>
        <select id="product" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
          {/* Options for product selection */}
        </select>
      </div>

      <div>
        <label htmlFor="office">Office:</label>
        <select id="office" value={selectedOffice} onChange={(e) => setSelectedOffice(e.target.value)}>
          {/* Options for office selection */}
        </select>
      </div>

      <div>
        <label htmlFor="price">Price:</label>
        <input type="number" id="price" value={price} onChange={(e) => setPrice(e.target.value)} />
      </div>

      <div>
        <label>
          <input type="checkbox" checked={isDefault} onChange={() => setIsDefault(!isDefault)} />
          Default
        </label>
      </div>

      <table>
        <TableHeader headers={dataHeaders} />
        {dataRows.map((row) => (
          <TableRowComponent
            key={row.id}
            row={row}
            headers={dataHeaders}
            onCellValueChange={handleCellValueChange}
          />
        ))}
      </table>

      {/* Save the tableData as JSON */}
      <pre>{JSON.stringify(tableData, null, 2)}</pre>
    </div>
  );
};

export default Home;
