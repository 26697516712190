import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// @mui
import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import emailValidator from "email-validator";
import { RequestChangePassword } from "../axios/auth";
import { successToast, errorToast } from "../utils/toast";
import { ToastContainer } from "react-toastify";

// ----------------------------------------------------------------------

export default function RecoverForm() {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleClick = async (value) => {
    const response = await RequestChangePassword(value);
    if (response.status === 200) {
      successToast(response.msg);
      setTimeout(() => {
        navigate("/", { replace: true });
      }, 4000);
    } else {
      errorToast(response.msg);
    }
    // if(response?.status){
    //   setLoading(false)
    //   dispatch(loginSuccess(response.data))
    //   Router.push('/home')
    // }else{
    //   setLoading(false)
    //   setError(response?.msg)
    //   setvisible(true)
    // }
    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleClick)}>
        <Stack spacing={3}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: "Correo electrónico es requerido",
              validate: (value) =>
                emailValidator.validate(value) || "Correo electrónico inválido",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Correo electrónico"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />

          <LoadingButton
            style={{ marginTop: 25 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!Object.keys(errors).length === 0}
          >
            Reestablecer contraseña
          </LoadingButton>
        </Stack>
      </form>
      {/* </Stack> */}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Typography
          style={{ marginBottom: 0 }}
          variant="subtitle2"
          sx={{ mb: 5 }}
        >
          Se te enviará un correo con las instrucciones a seguir, síguelas para
          restablecer tu contraseña.
        </Typography>
      </Stack>
      <ToastContainer />
    </>
  );
}
