import React, { useState } from 'react';
import CustomModal from '../CustomModal';
import { Button, Stack, TextField } from '@mui/material';

const ModalStatus = ({
    visible = false,
    onClose = () => { },
    itemDetail = {},
    actionForm = () => { }
}) => {

    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);

    const onSave = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            actionForm(itemDetail, { comment: comment?.trim() })
            handleClose()
        }, 2000)
    }

    const handleClose = () => {
        onClose()
        setComment('')
    }

    const onChange = ({ target: { value } }) => {
        setComment(value)
    }

    return (
        <CustomModal
            visible={visible}
            title={`Finalizar orden ${itemDetail?.id}`}
            onClose={handleClose}
            loading={loading}
        >
            <TextField
                minRows={5}
                maxRows={5}
                multiline={true}
                value={comment}
                onChange={onChange}
                placeholder='Comentario'
            />
            <Stack direction='row' marginTop={1} spacing={1} justifyContent='flex-end'>
                <Button variant='outlined' onClick={() => handleClose()}>
                    Cancelar
                </Button>
                <Button variant='contained' onClick={()=> onSave()}>
                    Guardar
                </Button>
            </Stack>
        </CustomModal>
    )
}

export default ModalStatus