import { CloseOutlined } from '@mui/icons-material';
import { Box, ButtonBase, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import CustomLoading from './CustomLoading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    rowGap: 1,
    p: 2,
};


const CustomModal = ({
    children,
    visible = false,
    onClose = () => { },
    title = '',
    width = 500,
    closable = true,
    loading = false
}) => {
    return (
        <Modal
            open={visible}
            // onClose={onClose}
            disableEscapeKeyDown
            slotProps={{
                backdrop: { sx: { background: 'rgba(0,0,0,0.4)' } }
            }}
        >
            <Box sx={{ ...style, width }} display='flex' flexDirection='column'>
                {loading && <CustomLoading style={{borderRadius: 2}}/>}
                {(title || closable) && (
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='subtitle1'>
                            {title}
                        </Typography>
                        {closable && (
                            <ButtonBase onClick={() => onClose()}>
                                <CloseOutlined />
                            </ButtonBase>
                        )}
                    </Stack>
                )}
                {children}
            </Box>
        </Modal>
    )
}

export default CustomModal