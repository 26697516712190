import { toast } from "react-toastify";

export const successToast = (content) => {
  toast.success(content, {
    position: "top-right", // Puedes personalizar la posición
    autoClose: 3000, // Tiempo en milisegundos antes de que se cierre automáticamente
  });
};

export const warningToast = (content) => {
  toast.warning(content, {
    position: "top-right", // Puedes personalizar la posición
    autoClose: 3000, // Tiempo en milisegundos antes de que se cierre automáticamente
  });
};

export const errorToast = (content) => {
  toast.error(content, {
    position: "top-right", // Puedes personalizar la posición
    autoClose: 3000, // Tiempo en milisegundos antes de que se cierre automáticamente
  });
};
