import React, { useEffect, useState } from 'react';
import CustomTable from '../../components/CustomTable';
import CustomSearch from '../../components/CustomSearch';
import CustomSelect from '../../components/installer/CustomSelect';
import { Stack, Typography, ButtonBase } from '@mui/material';
import ModalDetail from '../../components/installer/ModalDetail';
import DrawerExtra from '../../components/installer/DrawerExtra';
import ModalStatus from '../../components/installer/ModalStatus';
import ModalFilters from '../../components/installer/ModalFilters';
import { AddOutlined, Visibility } from '@mui/icons-material';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    useParams,
    useNavigate,
    useLocation
} from 'react-router-dom';
import {
    getFiltersJB,
    createFiltersJB,
    getValueFilter
} from '../../utils/functions';
import { getAssignments } from '../../redux/installerSlice';
import { errorToast, successToast } from '../../utils/toast';
import { optionsStatus } from '../../utils/constants';

const getStatus = (value) => getValueFilter({
    value,
    list: optionsStatus,
    keyEquals: 'value',
    keyShow: 'label'
})

const listKeys = {
    status: {
        name: 'Estatus',
        get: getStatus
    },
    install: {
        name: 'Equipo'
    }

}

const Assign = () => {

    const params = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        list_assignments,
        load_assignments,
        installer_page,
        installer_page_size,
        installer_filters
    } = useSelector(state => state.installerStore)

    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openExtra, setOpenExtra] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [itemSelected, setItemSelected] = useState({});

    useEffect(() => {
        console.log('state->', params?.state)
        let page = params?.state?.page ? parseInt(params?.state?.page) : 1;
        let size = params?.state?.size ? parseInt(params?.state?.size) : 10;
        let filters = getFiltersJB({ obj: params?.state || {}, isFirst: true, discard: ['page'] });
        dispatch(getAssignments(filters, page, size))
    }, [params?.state])

    const actionStatus = async (item, data) => {
        try {
            // let response = await webApi.updateItem(item?.id, data)
            setTimeout(() => {
                dispatch(getAssignments(installer_filters, installer_page, installer_page_size))
                successToast('Estatus actualizado')
            }, 1000)
        } catch (e) {
            console.log(e)
            errorToast('Estatus no actualizado')
        }
    }

    const showModal = (item) => {
        setItemSelected(item)
        setOpenModal(true)
    }

    const closeModal = () => {
        setItemSelected({})
        setOpenModal(false)
    }

    const showExtra = (item) => {
        setItemSelected(item)
        setOpenExtra(true)
    }

    const closeExtra = () => {
        setItemSelected({})
        setOpenExtra(false)
    }

    const closeStatus = () => {
        setItemSelected({})
        setOpenStatus(false)
    }

    const onChangeStatus = (item, status) => {
        if (status == 2) {
            actionStatus(item, { status });
            return;
        }
        setOpenStatus(true)
        setItemSelected(item)
    }

    const onPageChange = (values = {}) => {
        let state = params?.state || {};
        navigate('/installer/assign', {
            state: { ...state, ...values },
            replace: true
        })
    }

    const columns = [
        {
            title: 'ID Orden',
            dataIndex: 'id'
        },
        {
            title: 'Equipo a instalar',
            dataIndex: 'install'
        },
        {
            title: 'Vendedor',
            dataIndex: 'seller'
        },
        {
            title: 'Detalle',
            render: (item) => (
                <ButtonBase onClick={() => showModal(item)}>
                    <Visibility color='primary' fontSize='small' />
                </ButtonBase>
            )
        },
        {
            title: 'Gastos',
            render: (item) => item?.status > 1 ? (
                <ButtonBase onClick={() => showExtra(item)}>
                    {item?.status == 2
                        ? <AddOutlined color='primary' fontSize='small' />
                        : <Visibility color='primary' fontSize='small' />}
                </ButtonBase>
            ) : <></>
        },
        {
            title: 'Estatus',
            render: (item) => (
                <CustomSelect
                    width={125}
                    value={item?.status}
                    keyLabel='label'
                    keyValue='value'
                    placeholder='Estatus'
                    options={optionsStatus}
                    onChange={value => onChangeStatus(item, value)}
                    disabled={item?.status == 3}
                />
            )
        },
    ]

    return (
        <>
            <CustomSearch
                title='Mi trabajo'
                value={search}
                onChange={setSearch}
                onDelete={(state) => navigate('/installer/assign', { state, replace: true })}
                onReset={() => navigate('/installer/assign', { state: {}, replace: true })}
                onOpen={() => setOpenFilter(true)}
                filters={params?.state || {}}
                listKeys={listKeys}
            />
            <CustomTable
                rowKey='id'
                columns={columns}
                page={installer_page}
                loading={load_assignments}
                items={list_assignments?.results}
                rowsPerPage={installer_page_size}
                count={list_assignments?.count}
                onPageChange={onPageChange}
                onRowsPerPageChange={onPageChange}
            />
            <ModalDetail
                visible={openModal}
                onClose={closeModal}
                itemDetail={itemSelected}
            />
            <DrawerExtra
                visible={openExtra}
                onClose={closeExtra}
                itemDetail={itemSelected}
                onReady={() => {
                    dispatch(getAssignments(installer_filters, installer_page, installer_page_size))
                }}
            />
            <ModalStatus
                visible={openStatus}
                onClose={closeStatus}
                actionForm={actionStatus}
                itemDetail={itemSelected}
            />
            <ModalFilters
                visible={openFilter}
                onClose={() => setOpenFilter(false)}
                actionForm={(state) => navigate('/installer/assign', { state, replace: true })}
            />
        </>
    )
}

export default Assign